/* Other */
import { UUID } from 'angular2-uuid';
import { AuthProvider } from '../providers/auth/auth';
import { FirebaseProvider2 } from '../providers/firebase/firebase';

/* Providers */
// import { FirebaseProvider } from '../providers/firebase/firebase';

/* Types and interfaces */
import { User } from './user.store';


export class Site {
  id: string = UUID.UUID();
  name = '(new site)';
  ownerId: string  = null;
          users    = null;

  owner: User;
  teamId: string;

  private fbProviderVar = null;
  // private get fbProvider() {
  //   if (!this.fbProviderVar && this.fbProvider !== null) {
  //     this.fbProviderVar = this.fbProvider;
  //   }
  //   return this.fbProviderVar;
  // }

  constructor(element: any = null,
    private fbProvider: FirebaseProvider2 = null) {
    if (element !== null) {
      Object.keys(element).forEach(key => {
        if (this.hasOwnProperty(key)) {
          this[key] = element[key];
        }
      });
      this.getOwner();
    }
  }

  getOwner() {
    if (this.fbProvider !== null) {
      this.owner = new User(this.fbProvider.getUserById(this.ownerId));
    }
    // this._fbProvider.getUserById(this.ownerId).then(user => this.owner = new User(user));
  }
}
