import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { User } from '../stores/user.store';
import { AuthProvider } from '../providers/auth/auth';
import { FirebaseProvider2 } from '../providers/firebase/firebase';
import { firebaseConfig } from 'src/environments/environment';
import { InitialPage } from '../pages/initial/initial';
import { StatusBar } from '@ionic-native/status-bar';
import { SplashScreen } from '@ionic-native/splash-screen';
// import { SplashScreenOriginal } from '@ionic-native/splash-screen';
import * as firebase from 'firebase/app';
import 'firebase/auth';

@Component({
  selector: 'app-home',
  templateUrl: 'home.page.html',
  styleUrls: ['home.page.scss'],
})
export class HomePage {

  rootPage: any;
  user: User;


    constructor(
        platform: Platform,
        changeDetectorRef: ChangeDetectorRef,
        authProvider: AuthProvider,
        fbProvider: FirebaseProvider2
    ) {
        // initiate firebase
        // if (!firebase.apps.length) {
        //     firebase.initializeApp(firebaseConfig);
        //     console.log('Firebase: Initialized', firebase);
        // };

        // fbProvider.dbRootNode = '/';
        // fbProvider.setDBRootNodeRef();
        // fbProvider.addDBNodeListeners();
        fbProvider.initFirebase();

        // It is listening to the authentication state.
        // When you log in or signup using Firebase, it will store an
        // auth object inside your localStorage.
        const unsubscribe = firebase.auth().onAuthStateChanged(async user => {
            // If the user doesn’t exist, the user variable will be null
            if (!user || !localStorage.getItem('uid')) {
              authProvider.logoutUser();
            }

            this.rootPage = InitialPage;
            unsubscribe();

            // for some reason change detection was not triggered here
            changeDetectorRef.detectChanges();

            // The unsubscribe(); is because we’re telling the function to call itself
            // once it redirects the user, this is because the onAuthStateChanged()
            // returns the unsubscribe function for the observer. Meaning it will stop
            // listening to the authentication state unless you run it again (it runs every
            // time someone opens the app). You could technically remove the unsubscribe()
            // and let the function keep listening, but I find it’s a good practice to
            // unsubscribe in most of the cases, because if there’s a sudden change in
            // the authentication state that function will trigger.
        });

        platform.ready().then(() => {
            // Okay, so the platform is ready and our plugins are available.
            // Here you can do any higher level native things you might need.
            StatusBar.backgroundColorByHexString('#185B51');
            // statusBar.setBackgroundColor({
            //   color: '#185B51'
            // });
            SplashScreen.hide();
        });


    }


}
