/* Ionic & Angular core imports */
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { MobxAngularModule } from 'mobx-angular';
import { GlobalComponentsModule } from '../global/global.components.module';
import { EventEditorComponent } from './event-editor/event-editor.component';

/* Components */

@NgModule({
	declarations: [
    EventEditorComponent
  ],
  imports: [
    IonicModule,
    FormsModule,
    GlobalComponentsModule,
    CommonModule
  ],
  exports: [
    EventEditorComponent
  ],
  entryComponents: [
    EventEditorComponent
  ]
})
export class EventsComponentsModule {}
