/* Ionic & Angular core imports */
import { Component, ChangeDetectorRef, OnInit, AfterViewInit } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { ModalController, PopoverController } from '@ionic/angular';
import { ApplicationProvider } from 'src/app/providers/application/application';

@Component({
  selector: 'app-autoplay-config',
  templateUrl: 'autoplay-config.html',
  styleUrls: ['autoplay-config.scss']
})
export class AutoPlayConfigPopoverComponent implements OnInit, AfterViewInit {

  secondsBetween = 10;
  repeat = false;
  randomize = false;

  constructor(
    private popoverCtrl: PopoverController,
    private detector: ChangeDetectorRef,
    private storage: Storage,
    private appProvider: ApplicationProvider
  ) {
  }

  async ngOnInit() {

    await this.storage.create();

    await this.storage.get('secondsBetween').then((val) => {
      if (val != null) {
        this.secondsBetween = val;
      }
    });

    await this.storage.get('repeat').then((val) => {
      if (val != null) {
        this.repeat = val;
      }
    });


    await this.storage.get('randomize').then((val) => {
      if (val != null) {
        this.randomize = val;
      }
    });
  }

  async ngAfterViewInit() {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.

    await this.appProvider.dismissLoading();
  }

  close() {
    this.popoverCtrl.dismiss(null);
  }
  async start() {
    await this.storage.set('secondsBetween', this.secondsBetween);
    await this.storage.set('repeat', this.repeat);
    await this.storage.set('randomize', this.randomize);
    this.popoverCtrl.dismiss({

      secondsBetween: this.secondsBetween,
      repeat: this.repeat,
      randomize: this.randomize
    });
  }
  updateRepeat() {
    this.detector.detectChanges();
  }

  updateRandomize() {
    this.detector.detectChanges();
  }
}
