/* Ionic & Angular core imports */
import { Component, Input, Output, EventEmitter, ChangeDetectorRef, OnDestroy } from '@angular/core';

/* Types & Interfaces */
import { Screen } from '../../../stores/screen.store';
import { action } from 'mobx-angular';
import { FirebaseProvider2 } from 'src/app/providers/firebase/firebase';

@Component({
  selector: 'app-screen-editor',
  templateUrl: 'screen-editor.html'
})
export class ScreenEditorComponent implements OnDestroy {
  @Input() screen: Screen;
  @Input() index: number;
  @Output() removeClicked: EventEmitter<Screen> = new EventEmitter();

  screenRemoved = false;

  constructor(
    private detector: ChangeDetectorRef,
    public fbProvider: FirebaseProvider2
  ) {

  }

  @action updateValue(field: string, value: any) {
    this.screen[field] = value;
  }

  ngOnDestroy() {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    // console.log("Destroy screen: ", this.screenRemoved)

    if (!this.screenRemoved) {
      // console.log("SCREEN NOT REMOVED")
      this.fbProvider.updateScreen(this.screen, this.index);
    }
  }

  removeScreen() {
    this.screenRemoved = true;
    this.removeClicked.emit(this.screen);
    // console.log("Remove screen: ", this.screenRemoved)
  }


  addValue(field: string, v: number, event?: any) {
    this.screen[field] = +this.screen[field] + v;
    this.detector.detectChanges();
  }
}
