export class Result {
    left  : number = 0;
    right : number = 0;
    period: string = '1.';

    constructor(element: any = null) {
        for (let key in element) {
            if (this.hasOwnProperty(key)) {
                this[key] = element[key];
            }
        }
    }
}