import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { PlayerEditorComponent } from 'src/app/components/players/player-editor/player-editor.component';
import { ApplicationProvider } from 'src/app/providers/application/application';
import { FirebaseProvider2 } from 'src/app/providers/firebase/firebase';
import { Player } from 'src/app/stores/player.store';
import { ApplicationConfig } from 'src/environments/environment';

@Component({
  selector: 'app-players',
  templateUrl: './players.page.html',
  styleUrls: ['./players.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlayersPage implements OnInit {

  externalServerAddress: string;

  constructor(
    public changeDetectorRef: ChangeDetectorRef,
    public fbProvider: FirebaseProvider2,
    private applicationProvider: ApplicationProvider,
    private modalCtrl: ModalController,
    appConfig: ApplicationConfig
  ) {
    this.externalServerAddress = appConfig.externalLibrary.viewURL;
  }

  ngOnInit() {
    this.fbProvider.playersUpdated.subscribe(() => {
      this.detectChanges();
      console.log(Object.entries(this.fbProvider.sitePlayers))
    });
  }

  async setup() {
    await this.fbProvider.initFirebase(this.changeDetectorRef);
    await this.fbProvider.getCurrentUser();
  }

  async addNewPlayer() {
    if (this.fbProvider.currentSite != null) {
      this.fbProvider.updatePlayer(new Player(null, this.fbProvider.currentSite.id))
        .then(async () => {
          const modal = await this.modalCtrl.create({
            component: PlayerEditorComponent,
            componentProps: {
              player: this.fbProvider.sitePlayers[this.fbProvider.sitePlayers.length - 1]
            }
          });

          modal.onWillDismiss().then(() => this.detectChanges());

          modal.present();
        }
      );
    }
  }

  async removePlayer(player: Player) {
    const currentPlayer = this.fbProvider.sitePlayers
      .filter(currentPlayer => {
        if (currentPlayer.id === player.id) {
          return currentPlayer;
        }
      })
      .reduce((_, player) => player, null);

    this.applicationProvider.confirmDialog(
      `Do you really want to remove <b>${currentPlayer.firstName} ${currentPlayer.lastName}</b>?`,
      () => {
        this.fbProvider.removePlayer(player);

        this.detectChanges();
      }
    );
  }

  async editPlayer(player: Player) {
    const modal = await this.modalCtrl.create({
      component: PlayerEditorComponent,
      componentProps: { player: Object.assign({}, player)}
    });

    modal.onWillDismiss().then(() => {
      this.detectChanges();
    });

    modal.present();
  }

  getPlayerPhotoFace(player: Player) {
    const image = this.fbProvider.libraryStore
      .filter(image => {
        if (image.id == player.photoFace) {
          return image;
        }
      })
      .reduce((_, image) => image, {
        isOnExternalServer: false,
        source: ""
      });

    return image;
  }

  detectChanges() {
    this.changeDetectorRef.detectChanges();
  }

}
