import { UUID } from 'angular2-uuid';

export class Event {
  id = UUID.UUID();
  siteId = '';
  name = '(New Event)';
  media: string;
  sponsor: string;
  quickAccessLogo: string;
  logo: string;

  constructor(element: any = null, siteId: string) {
    this.siteId = siteId;
    if (element) {
      element.forEach(key => {
        if (this.hasOwnProperty(key)) {
          this[key] = element[key];
        }
      });
    }
  }
}
