/* Ionic & Angular core imports */
import { Component, OnInit, OnDestroy, ChangeDetectorRef, Input, ChangeDetectionStrategy } from '@angular/core';
import { ModalController, NavParams, PopoverController } from '@ionic/angular';
import { ActivatedRoute } from '@angular/router';

/* Types & Interfaces */
import { Screen, ScoreboardSettings } from '../../stores/screen.store';
import { Media } from '../../stores/media.store';
import { Site } from '../../stores/sites.store';
import { Result } from '../../stores/result.store';

/* Providers */
import { ApplicationProvider } from '../../providers/application/application';
import { FirebaseProvider2 } from 'src/app/providers/firebase/firebase';

/* Components */
import { MediaPickerComponent } from 'src/app/components/library/media-picker/media-picker';
import { MediaPreviewComponent } from '../../components/library/media-preview/media-preview';
import { toJS, intercept, runInAction } from 'mobx';
import { observable, action } from 'mobx-angular';
import { OverlayEventDetail } from '@ionic/core';
import { AuthProvider } from 'src/app/providers/auth/auth';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-page-controller',
  templateUrl: 'controller.html',
  styleUrls: ['controller.scss']
})
export class ControllerPage implements OnInit, OnDestroy {

  @observable screens: Screen[];
  // @Input() screensInput: Screen[];

  slideOptions = {
    centeredSlides: false
  };
  site: Site;


  constructor(
    public changeDetectorRef: ChangeDetectorRef,
    public fbProvider: FirebaseProvider2,
    private appProvider: ApplicationProvider,
    private modalCtrl: ModalController,
    private popoverCtrl: PopoverController,
    private activatedRoute: ActivatedRoute,
    private authProvider: AuthProvider
  ) {
    this.activatedRoute.params.subscribe(params => {
      this.site = new Site(params.site, this.fbProvider);
    });
    // loading should be dismissed
    // when settings are loaded from database
    this.screens = this.fbProvider.listOfScreens;
    this.screens.forEach(screen => {
      if (!screen.scoreboardSettings) {
        screen.scoreboardSettings = new ScoreboardSettings();
      }
    });
    // this.presentLoading();
  }

  @action async changeMedia(screen: Screen) {
    const modal = await this.modalCtrl.create({
      component: MediaPickerComponent
    });
    await modal.present();
    modal.onWillDismiss().then((overlayEvent: OverlayEventDetail<Media>) => {
      if (overlayEvent.data) {
        screen.currentMedia = overlayEvent.data;
      }
    });
  }

  @action updateScreenProperty(idx: number, property: string, value: any) {
    const screen = this.fbProvider.listOfScreens[idx];
    screen[property] = value;
    this.fbProvider.updateScreen(screen, idx);
  }

  @action updateScoreboardProperty(idx: number, property: string, value: any) {
    const screen = this.fbProvider.listOfScreens[idx];
    console.log("Scoreboard:: ", property, ": ", value);
    runInAction(() => {
      screen.scoreboardSettings[property] = value;
    });
    this.fbProvider.updateScreen(screen, idx);
  }

  // ngOnInit is required as if this is not implemented,
  // then it is not called and we create dynamically ngOnInit
  // function in AutoAsync decorator
  async ngOnInit() {
    // this.screens
    this.fbProvider.screensUpdated.subscribe(
      () => this.changeDetectorRef.detectChanges()
    );
    await this.dismissLoading();
  }
  ngOnDestroy() { }


  async clearMediaForScreen(screen: Screen) {
    // confirm action
    await this.appProvider.confirmDialog(`Do you really want to clear media for that screen?`, () => {
      screen.currentMedia = null;
    });
  }

  /**
   *
   *
   * Function that is called when data is being loaded
   * for the first time
   */
  async dismissLoading() {
    // dismiss loading message and overlay
    await this.appProvider.dismissLoading();
  }

  async presentLoading() {
    await this.appProvider.presentLoadingDefault();
  }

  async previewMedia(media: Media) {
    const modal = await this.modalCtrl.create({
      component: MediaPreviewComponent,
      componentProps: media
    });
    await modal.present();
  }


  async changeResult(screen: Screen) {
    if (screen.isResultScreen) {
      const popover = await this.popoverCtrl.create({
        component: ChangeResultPopoverComponent,
        componentProps: {
          left: screen.result.left,
          right: screen.result.right,
          period: screen.result.period,
        }
      });
      popover.onWillDismiss().then((result) => {
        if (!!result) {
          // console.log(result);
          screen.result = new Result(result.data);
        }
      });
      popover.present();
    }
  }
}


@Component({
  template: `
    <form>
      <ion-item>
      <ion-label position="stacked">Left</ion-label>
        <ion-input
          type             = "number"
          [(ngModel)]      = "left"
          [ngModelOptions] = "{standalone: true}"
        ></ion-input>
      </ion-item>
      <ion-item>
        <ion-label position="stacked">Right</ion-label>
        <ion-input
          type             = "number"
          [(ngModel)]      = "right"
          [ngModelOptions] = "{standalone: true}"
        ></ion-input>
      </ion-item>
      <ion-item>
        <ion-label position="stacked">Period</ion-label>
        <ion-input
          [(ngModel)]      = "period"
          [ngModelOptions] = "{standalone: true}"
        ></ion-input>
      </ion-item>
      <ion-item>
        <button ion-button full  (click)="save()">Save</button>
      </ion-item>

    </form>
  `
})
export class ChangeResultPopoverComponent {

  left: number;
  right: number;
  period: string;

  constructor(
    private viewCtrl: ModalController,
    params: NavParams
  ) {
    this.left = params.get('left');
    this.right = params.get('right');
    this.period = params.get('period');
  }
  save() {
    this.viewCtrl.dismiss({
      left: this.left || 0,
      right: this.right || 0,
      period: this.period || 1,
    });
  }
}
