/* Ionic & Angular core imports */
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { NavController, ModalController, MenuController, IonList } from '@ionic/angular';

/* Types and interfaces */
import { Site } from '../../stores/sites.store';

/* Providers */
// import { FirebaseProvider } from '../../providers/firebase/firebase';
import { ApplicationProvider } from '../../providers/application/application';
import { SiteEditorComponent } from '../../components/sites/site-editor/site-editor';

/* Pages */
import { User } from '../../stores/user.store';
import { observable } from 'mobx-angular';
import { FirebaseProvider2 } from 'src/app/providers/firebase/firebase';
import { AuthProvider } from 'src/app/providers/auth/auth';

@Component({
  selector: 'app-page-sites',
  templateUrl: 'sites.html',
  styleUrls: ['sites.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SitesPage implements OnInit {
  // reference to ion-list so we can close
  // all opened slider menus it from here
  @ViewChild(IonList) list: IonList;

  @observable sites: Site[];
  currentUserId: string;

  init = true;


  constructor(
    private navCtrl: NavController,
    public fbProvider: FirebaseProvider2,
    public changeDetectorRef: ChangeDetectorRef,
    private appProvider: ApplicationProvider,
    private modalCtrl: ModalController,
    // private _events     : Events,
    private authProvider: AuthProvider,
    private menuCtrl: MenuController,
  ) {
    this.menuCtrl.enable(true, 'main-menu');
  }

  async ngOnInit() {
    await this.build();
    this.fbProvider.sitesUpdated.subscribe(
      () => {
        console.log("sites updated")
        this.changeDetectorRef.detectChanges()
      }
    );
    if (this.fbProvider.availableSites.length > 0) {
      this.changeDetectorRef.detectChanges();
    }
    await this.appProvider.dismissLoading();
  }

  async build() {
    await this.appProvider.presentLoadingDefault();
    await this.fbProvider.initFirebase();
    await this.fbProvider.getCurrentUser();
    this.loadCurrentUser();

    this.sites = this.fbProvider.availableSites;
    if (this.init) {
      let selectedSite;
      this.fbProvider.availableSites.forEach(site => {
        if (site.id === localStorage.getItem('siteId')) {
          selectedSite = site;
        }
      });

      if (selectedSite) {
        this.selectSite(selectedSite);
      }
      console.log('INIT SITE');
      this.init = false;

    }

    // enable menu swiping
    // menuCtrl.swipeEnable(true);
    this.menuCtrl.swipeGesture(true);
  }

  loadCurrentUser() {
    const user: User = this.fbProvider.currentUser;
    // this._events.publish('user:loaded', user);
  }

  async loadAvailableSites(refresher = null) {

    if (!refresher) {
      await this.appProvider.presentLoadingDefault();
    }

    try {
      const sites = this.fbProvider.availableSites;

      // if site is already selected than move to tabs page
      const siteId = localStorage.getItem('siteId');
      if (siteId) {
        if (sites[siteId]) {
          // publish event that site was selected
          // used in main menu
          // this._events.publish('site:changed', sites[siteId]);
          // this._navCtrl.setRoot(TabsPage, { site: sites[siteId] });
          this.navCtrl.navigateRoot('tabs', { queryParams: { site: sites[siteId] } });
        } else {
          localStorage.removeItem('siteId');
        }
      }

      this.sites = [];
      sites.forEach(site => {
        this.sites.push(new Site(site, this.fbProvider));
      });

    } catch (e) {
      this.appProvider.displayDebugInformation(e);
      this.appProvider.showInformation('Error while loading sites');
    }

    if (refresher) {
      refresher.complete();
    } else {
      await this.appProvider.dismissLoading();
    }
  }

  async addSite() {
    this.list.closeSlidingItems();

    // confirm action
    this.appProvider.confirmDialog('Do you really want to add new site?', async () => {
      await this.appProvider.presentLoadingDefault();

      // create new screen
      const site = new Site(null, this.fbProvider);

      // mark owner of the site
      site.ownerId = this.fbProvider.userId;

      // add user to users that can access this site
      site.users = {};
      site.users[site.ownerId] = true;

      // setup default name
      site.name = `New site (${this.sites ? this.sites.length + 1 : 1})`;

      try {
        await this.fbProvider.updateSite(site);
        site.getOwner();

        if (!this.sites) {
          // overwrite completely property
          this.sites = [site];
        } else {
          // add screen to the array.
          // this will automatically synchronize with database
          this.sites.push(site);
        }
      } catch (e) {
        this.appProvider.displayDebugInformation(e);
        this.appProvider.showInformation('Error while adding new site');
      }

      await this.appProvider.dismissLoading();
    });
  }

  async removeSite(site: Site) {
    // confirm action
    this.appProvider.confirmDialog(`Do you really want to remove site <b>${site.name}</b>?`, async () => {
      await this.appProvider.presentLoadingDefault();

      try {
        await this.fbProvider.removeSite(site);
        const index = this.sites.findIndex(s => s.id === site.id);
        this.sites.splice(index, 1);
      } catch (e) {
        this.appProvider.displayDebugInformation(e);
        this.appProvider.showInformation('Error while removing site');
      }

      await this.appProvider.dismissLoading();
    });
  }

  async editSite(site: Site) {
    await this.list.closeSlidingItems();

    const modal = await this.modalCtrl.create({
      component: SiteEditorComponent,
      componentProps: { site }
    });
    modal.onWillDismiss().then(async val => {
      await this.appProvider.dismissLoading();
    });
    await modal.present();
  }

  async selectSite(site: Site) {
    await this.appProvider.presentLoadingDefault();
    localStorage.setItem('siteId', site.id);
    // publish event that site was selected
    // used in main menu
    // this._events.publish('site:changed', site);
    await this.navCtrl.navigateRoot('overview/library', { queryParams: { siteId: site.id } });
    // this._navCtrl.setRoot(TabsPage, { site });
  }

}
