/* Angular & Ionic core imports */
import { Component, ViewChild, OnInit, OnDestroy, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { IonList, IonTabs, ModalController, NavController, NavParams } from '@ionic/angular';

/* Types & Interfaces */
import { Media, Presentation } from '../../stores/media.store';
import { Site } from '../../stores/sites.store';

/* Providers */
import { ApplicationProvider } from '../../providers/application/application';

/* Uploader */
import { FileUploader, Headers } from 'ng2-file-upload';

/* Config */
import { ApplicationConfig } from 'src/environments/environment';

/* Components */
import { MediaPreviewComponent } from '../../components/library/media-preview/media-preview';
import { MediaEditorComponent } from '../../components/library/media-editor/media-editor';

import { Pipe, PipeTransform } from '@angular/core';
import { observable, action, computed } from 'mobx-angular';
import { autorun, observe, reaction, toJS, when } from 'mobx';
import { FirebaseProvider2 } from 'src/app/providers/firebase/firebase';
import { ActivatedRoute } from '@angular/router';
import { AuthProvider } from 'src/app/providers/auth/auth';
import { OverlayEventDetail } from '@ionic/core';

@Pipe({ name: 'reverse' })

export class ReversePipe implements PipeTransform {
  transform(value) {
    return value.slice().reverse();
  }
}

@Component({
  selector: 'app-page-library',
  templateUrl: 'library.html',
  styleUrls: ['library.scss'],
  // TODO: Fix to change detection to OnPush
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LibraryPage implements OnInit, OnDestroy {

  @observable selectedMediaForSlides: Media[] = [];
  // reference to ion-list so we can close
  // all opened slider menus it from here
  @ViewChild(IonList) list: IonList;

  // reference to fileUpload field
  @ViewChild('fileUpload') fileUploadInput;

  // fab button menu for upload
  @ViewChild('uploadMenu') fabUploadMenu;

  siteLibrary: Media[] = [];

  // file uploader
  uploader: FileUploader;

  // slides
  slideSelection = false;

  constructor(
    public changeDetectorRef: ChangeDetectorRef,
    public fbProvider: FirebaseProvider2,
    private applicationProvider: ApplicationProvider,
    private applicationConfig: ApplicationConfig,
    private modalCtrl: ModalController,
    private activatedRoute: ActivatedRoute,
    private authProvider: AuthProvider,
    private navCtrl: NavController,
  ) {
    // this.site = new Site(this.fbProvider.currentSite, this.authProvider);
    // console.log(this.site);
    this.setupUploader();
  }

  @action startSlideSelection(media: Media) {
    if (this.slideSelection) {
      return;
    }
    this.selectedMediaForSlides = [media];
    this.slideSelection = true;
    this.detectChanges();
  }

  @action isMediaForSlides(media: Media) {
    return !!this.selectedMediaForSlides.find(m => m.id === media.id);
  }

  @action toggleMediaForSlides(media: Media) {
    if (this.slideSelection) {
      const index = this.selectedMediaForSlides.findIndex(m => m.id === media.id);
      if (index === -1) {
        this.selectedMediaForSlides.push(media);
      } else {
        this.selectedMediaForSlides.splice(index, 1);
      }
      this.detectChanges();
    }
  }

  @action async addToPresentation(presentationId: string) {
    console.log('PresentationId: ', presentationId);
    await this.applicationProvider.presentLoadingDefault();
    if (presentationId === 'NEW') {
      this.createPresentation();
    } else {
      const presentation = this.fbProvider.listOfCollections.find(p => p.id === presentationId);
      this.selectedMediaForSlides.forEach(media => {
        console.log(media.id);
        if (presentation.mediaIds === undefined) {
          presentation.mediaIds = [media.id];
          const index = this.fbProvider.listOfCollections.findIndex(s => s.id === presentation.id);
          this.fbProvider.updatePresentationMediaIds(presentation, presentation.mediaIds);
        } else if (presentation.mediaIds.indexOf(media.id) === -1) {
          presentation.mediaIds.push(media.id);
          const index = this.fbProvider.listOfCollections.findIndex(s => s.id === presentation.id);
          this.fbProvider.updatePresentationMediaIds(presentation, presentation.mediaIds);
        }
      });
      presentation.mediaIds = Array.from(presentation.mediaIds);
      this.deselectAndMoveToSlides();
    }
  }

  async setup() {
    // observe(this.fbProvider.libraryStore, change => {
    //   console.log(change);
    //   this.dismissLoading();
    // });
    // reaction(() => this.fbProvider.libraryStore, library => {
    //   console.log("LIBRARY: ",library);
    // })
    await this.applicationProvider.presentLoadingDefault();
    await this.fbProvider.initFirebase(this.changeDetectorRef);
    await this.fbProvider.getCurrentUser();
    // this.fbProvider.loginSubject.subscribe(() => {
    //   this.availableLibrary = this.fbProvider.libraryStore;
    //   console.log("login", this.fbProvider.libraryStore)
    //   this.changeDetectorRef.detectChanges();
    //   this.detectChanges();
    // });
    // console.log(this.fbProvider.currentUser);
    // this.site = new Site(this.fbProvider.currentSite, this.fbProvider);
    // localStorage.setItem('siteId', this.site.id);

    // loading should be dismissed
    // when library are loaded from database
    // await this.applicationProvider.presentLoadingDefault();
    // this.changeDetectorRef.markForCheck();
    // setInterval(() => {
    //   this.detectChanges();
    // }, 1000);

    // console.log("Library site:", this.site.id);
    // setup uploader
  }

  // ngOnInit is required as if this is not implemented,
  // then it is not called and we create dynamically ngOnInit
  // function in AutoAsync decorator
  async ngOnInit() {
    // await this.applicationProvider.presentLoadingDefault();
    this.siteLibrary = this.fbProvider.libraryStore;
    console.log("SITE LIBRARY", this.fbProvider.libraryStore, localStorage.getItem('siteId'))
    this.fbProvider.libraryUpdated.subscribe(async () => {
      this.siteLibrary = this.fbProvider.libraryStore;
      // if (JSON.stringify(this.siteLibrary) != JSON.stringify(this.fbProvider.libraryStore)) {
      //   console.log("site library")
      // }
      this.detectChanges()
      await this.dismissLoading();
    });
    await this.setup();
    if (Object.entries(this.fbProvider.libraryStore).length > 0) {
      await this.applicationProvider.dismissLoading();
    }
  }
  ngOnDestroy() { }


  detectChanges() {
    this.changeDetectorRef.detectChanges();
  }

  /**
   * Function that is called when data is being loaded
   * for the first time
   */
  async dismissLoading() {
    // dismiss loading message and overlay
    await this.applicationProvider.dismissLoading();
  }

  setupUploader() {

    // await this.applicationProvider.presentLoadingDefault();
    // setup uploader
    const headers: Headers[] = [{
      name: 'Access-Control-Allow-Origin',
      value: '*'
    }];

    this.uploader = new FileUploader({
      url: this.applicationConfig.externalLibrary.uploadURL,
      headers
    });

    this.uploader.onBeforeUploadItem = (item) => {
      item.withCredentials = false;
    };

    this.uploader.onProgressAll = (progress: any) => {
      this.detectChanges();
    };

    this.uploader.onCompleteItem = async (item: any, response: any, status: any) => {
      try {
        const responsePath = JSON.parse(response);
        if (!responsePath.status) {
          await this.applicationProvider.showInformation(`Failed. ${responsePath.msg}`);
          await this.applicationProvider.dismissLoading();
        } else {
          const media = new Media({
            source: `${responsePath.generatedName}`,
            name: responsePath.originalName,
            mime: responsePath.mime,
            thumbnail: responsePath.thumbnail,
            size: responsePath.size,
            md5: responsePath.md5,
            resolution: responsePath.resolution,
            isOnExternalServer: true
          }, localStorage.getItem('siteId'));

          // add to library
          if (!this.fbProvider.libraryStore) {
            this.fbProvider.updateLibraryStore(media).then(async () => {
              await this.applicationProvider.showInformation(`Success`);
              await this.applicationProvider.dismissLoading();
            });
          } else {
            this.fbProvider.updateLibraryStore(media).then(async () => {
              await this.applicationProvider.showInformation(`Success`);
              await this.applicationProvider.dismissLoading();
            });
          }

        }
      } catch (error) {
        await this.applicationProvider.showInformation(`Failed. ${error}`);
        await this.applicationProvider.dismissLoading();
      }

      this.uploader.clearQueue();

      // clear input field so we can select same file again
      this.fileUploadInput.nativeElement.value = '';

      this.detectChanges();
    };
  }

  async upload() {
    await this.list.closeSlidingItems();
    await this.applicationProvider.presentLoadingDefault();
    this.uploader.uploadAll();
  }

  async previewMedia(media: Media) {
    // cannot preview when selecting images for slides
    if (this.slideSelection) {
      this.toggleMediaForSlides(media);
      return;
    }
    this.fabUploadMenu.close();
    const modal = await this.modalCtrl.create({
      component: MediaPreviewComponent,
      componentProps: { media: Object.assign({}, media) }
    });

    modal.onWillDismiss().then((event: OverlayEventDetail<{ selectedScreen: string; media: Media }>) => {
      if (event.data && event.data.selectedScreen) {
        if (event.data.selectedScreen === 'ALL') {
          this.fbProvider.listOfScreens.forEach((screen, index) => {
            screen.mediaStatus = true;
            screen.currentMedia = event.data.media;
            // turn off autoplay
            screen.autoplay = null;
            this.fbProvider.updateScreen(screen, index);
          });
        } else {
          this.fbProvider.listOfScreens.find((screen, index) => {
            if (screen.id === event.data.selectedScreen) {
              screen.mediaStatus = true;
              screen.currentMedia = event.data.media;
              // turn off autoplay
              screen.autoplay = null;
              this.fbProvider.updateScreen(screen, index);
            }
          });
        }
      }
      // await this.appProvider.showInformation('Success');
      }
    );
    await modal.present();
  }

  async removeMedia(media: Media) {
    await this.list.closeSlidingItems();
    // cannot be done when selecting images for slides
    if (this.slideSelection) {
      return;
    }

    this.fabUploadMenu.close();
    // confirm action
    const idx = this.fbProvider.libraryStore.findIndex(m => m.id === media.id);

    await this.applicationProvider.confirmDialog(`Do you really want to remove file <b>${this.fbProvider.libraryStore[idx].name}</b>?`, async () => {
      if (idx > -1) {
        await this.applicationProvider.presentLoadingDefault();
        this.fbProvider.listOfCollections.forEach((presentation) => {
          if (presentation.mediaIds !== undefined) {
            const mediaIdx = presentation.mediaIds.findIndex(mId => mId === media.id);
            presentation.mediaIds.splice(mediaIdx, 1);
            if (mediaIdx > -1) {
              this.fbProvider.updatePresentationMediaIds(presentation, presentation.mediaIds);
            }
          }
        });

        this.fbProvider.removeMedia(idx, media);
        // this.library.splice(idx, 1);
        this.detectChanges();
        await this.applicationProvider.dismissLoading();
      }
    });
  }

  async addNewMedia() {
    this.fabUploadMenu.close();
    await this.list.closeSlidingItems();
    // this.library = [...this.library, new Media(null,this.site.id)];
    if (this.fbProvider.currentSite) {
      this.fbProvider.updateLibraryStore(new Media(null, this.fbProvider.currentSite.id)).then(
        async () => {
          const modal = await this.modalCtrl.create({
            component: MediaEditorComponent,
            componentProps: {
              media: this.fbProvider.libraryStore[this.fbProvider.libraryStore.length - 1]
            }
          });
          modal.present();
        }
      );
    }
  }

  async editMedia(media: Media) {
    await this.list.closeSlidingItems();
    // cannot be done when selecting images for slides
    if (this.slideSelection) {
      return;
    }

    this.fabUploadMenu.close();
    const modal = await this.modalCtrl.create({
      component: MediaEditorComponent,
      componentProps: { media: Object.assign({}, media) }
    });

    modal.onWillDismiss().then(() => {
      this.detectChanges();
    });
    modal.present();
  }


  async createPresentation() {
    if (this.fbProvider.currentSite) {
      await this.applicationProvider.presentLoadingDefault();
      const presentation: Presentation = new Presentation(null, this.fbProvider.currentSite.id);
      this.selectedMediaForSlides.forEach((media: Media) => {
        presentation.mediaIds.push(media.id);
      });
      if (this.fbProvider.listOfCollections) {
        this.fbProvider.updateCollection(presentation);
      } else {
        this.fbProvider.updateCollection(presentation);
      }
      this.deselectAndMoveToSlides();
    }
  }

  async deselectAndMoveToSlides() {
    this.slideSelection = false;
    this.selectedMediaForSlides = [];

    await this.applicationProvider.showInformation(`Success`);
    await this.applicationProvider.dismissLoading();

    // const tabs: IonTabs = this.navCtrl.pop();
    // tabs.select(0);
    this.detectChanges();
  }

  get sortByDate(): Media[] {
    return this.fbProvider.libraryStore.sort((media1, media2) =>
      media1.uploadedDate < media2.uploadedDate ? 1 : -1);
  }

  async openSponsorsPage(): Promise<any> {
    return this.navCtrl.navigateRoot('overview/library/sponsors');
  }

  async openSponsorTiersPage(): Promise<any> {
    return this.navCtrl.navigateRoot('overview/library/sponsor-tiers');
  }

  async openPlayersPage(): Promise<any> {
    return this.navCtrl.navigateRoot('overview/library/players');
  }

  async openEventsPage(): Promise<any> {
    return this.navCtrl.navigateRoot('overview/library/events');
  }
}
