/* Ionic & Angular core imports */
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

/* Components */
import { GlobalComponentsModule } from '../global/global.components.module';
import { MediaPreviewComponent } from './media-preview/media-preview';
import { MediaEditorComponent } from './media-editor/media-editor';

/* Videogular */
import { VgCoreModule, VgMediaDirective } from '@videogular/ngx-videogular/core';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';
import { MediaPickerComponent } from './media-picker/media-picker';
// import { VgCoreModule } from 'videogular2/compiled/core';
// import { VgControlsModule } from 'videogular2/compiled/controls';
// import { VgOverlayPlayModule } from 'videogular2/compiled/overlay-play';
// import { VgBufferingModule } from 'videogular2/compiled/buffering';

@NgModule({
    declarations: [
      MediaPreviewComponent,
      MediaEditorComponent,
      MediaPickerComponent
    ],
    imports: [
      IonicModule,
      GlobalComponentsModule,
      CommonModule,
      FormsModule,
      // videogular
      VgCoreModule,
      VgControlsModule,
      VgOverlayPlayModule,
      VgBufferingModule
    ],
    exports: [
      MediaPreviewComponent,
      MediaEditorComponent,
      MediaPickerComponent
    ],
    entryComponents: [
      MediaPreviewComponent,
      MediaEditorComponent,
      MediaPickerComponent
		]
})
export class LibraryComponentsModule { }
