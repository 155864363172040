import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { action, computed, observable, runInAction } from 'mobx';
import { FirebaseProvider2 } from 'src/app/providers/firebase/firebase';
import { Media, Sponsor, SponsorTier } from 'src/app/stores/media.store';
import { MediaPickerComponent } from '../../library/media-picker/media-picker';
import { OverlayEventDetail } from '@ionic/core';
import { ApplicationConfig } from 'src/environments/environment';
import { SponsorTierPickerComponent } from '../sponsor-tier-picker/sponsor-tier-picker.component';

@Component({
  selector: 'app-sponsor-editor',
  templateUrl: './sponsor-editor.component.html',
  styleUrls: ['./sponsor-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SponsorEditorComponent implements OnInit {

  sponsor: Sponsor;

  externalServerAddress: string;


  @observable logo: Media;
  @observable video: Media;
  @observable tier: SponsorTier;
  @observable tierLogo: Media;

  constructor(
    public fbProvider: FirebaseProvider2,
    public changeDetectorRef: ChangeDetectorRef,
    private modalCtrl: ModalController,
    appConfig: ApplicationConfig,
    params: NavParams
  ) {
    fbProvider.initFirebase();

    this.sponsor = Object.assign({}, params.get('sponsors'));
    this.externalServerAddress = appConfig.externalLibrary.viewURL;
  }

  ngOnInit() {
    this.fbProvider.sponsorsUpdated.subscribe(
      () => this.detectChanges()
    );
  }

  async addSponsorLogo() {
    this.addSponsorMedia('image');
  }

  async addSponsorVideo() {
    this.addSponsorMedia('video');
  }

  @action async addSponsorMedia(mediaType: string) {
    const modal = await this.modalCtrl.create({
      component: MediaPickerComponent,
      componentProps: { mediaType: mediaType }
    });
    modal.onWillDismiss().then((overlayEvent: OverlayEventDetail<Media>) => {
      if (overlayEvent.data) {
        runInAction(() => {
          switch (mediaType) {
            case 'image':
              this.sponsor.logo = overlayEvent.data.id;
              this.logo = overlayEvent.data;
              break;
            case 'video':
              this.sponsor.video = overlayEvent.data.id;
              this.video = overlayEvent.data;
              break;
          }
        });
        this.detectChanges();
      }
    });
    await modal.present();
  }

  @action async addSponsorTier() {
    const modal = await this.modalCtrl.create({
      component: SponsorTierPickerComponent
    });
    modal.onWillDismiss().then((overlayEvent: OverlayEventDetail<SponsorTier>) => {
      if (overlayEvent.data) {
        runInAction(() => {
          this.sponsor.sponsorTier = overlayEvent.data.id,
          this.tier = overlayEvent.data;
        });
        this.detectChanges();
      }
    });
    await modal.present();
  }

  @computed get sponsorVideo(): Media {
    const video = this.fbProvider.libraryStore.filter(video => {
      if (!video.isImage && video.id == this.sponsor.video) {
        return video;
      }
    }).reduce((_, video) => video, null);

    this.video = video;

    return this.video;
  }

  @computed get sponsorLogo(): Media {
    const image = this.fbProvider.libraryStore.filter(image => {
      if (image.isImage && image.id == this.sponsor.logo) {
        return image;
      }
    }).reduce((_, image) => image, null);

    this.logo = image;

    return this.logo;
  }

  @computed get sponsorTier(): SponsorTier {
    const tier = this.fbProvider.siteSponsorTiers.filter(tier => {
      if (tier.id === this.sponsor.sponsorTier) {
        return tier;
      }
    }).reduce((_, tier) => tier, null);

    this.tier = tier;

    return tier;
  }

  @computed get sponsorTierLogo(): Media {
    if (this.sponsorTier != undefined) {
      const image = this.fbProvider.libraryStore.filter(image => {
        if (image.id === this.sponsorTier.logo) {
          return image;
        }
      }).reduce((_, image) => image, null);

      this.tierLogo = image;

      return image;
    }
  }

  dismiss(save: boolean) {
    if (save) {
      if (this.sponsor.name == '(New Sponsor)') {
        this.fbProvider.removeSponsor(this.sponsor);
      } else {
        this.fbProvider.updateSponsor(this.sponsor);
      }
    } else {
      if (this.sponsor.name == '(New Sponsor)') {
        this.fbProvider.removeSponsor(this.sponsor);
      }
    }
    this.modalCtrl.dismiss();
  }

  detectChanges() {
    this.changeDetectorRef.detectChanges();
  }
}
