/* Ionic & Angular core imports */
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';

/* Pages */
import { InitialPage } from './initial';
import { MobxAngularModule } from 'mobx-angular';
import { CommonModule } from '@angular/common';
// import { ApplicationProvider } from 'src/app/providers/application/application';
import { FormBuilder } from '@angular/forms';
import { GlobalComponentsModule } from 'src/app/components/global/global.components.module';

@NgModule({
  declarations: [
    InitialPage,
  ],
  imports: [
    CommonModule,
    IonicModule,
    MobxAngularModule,
  ],
  providers: [
    FormBuilder
  ]
})
export class InitialPageModule { }
