/* Ionic & Angular core imports */
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';

/* Pages */
import { ChangeResultPopoverComponent, ControllerPage } from './controller';

/*Components */

/* Color picker */
import { ColorPickerModule } from 'ngx-color-picker';
import { GlobalComponentsModule } from '../../components/global/global.components.module';
import { MobxAngularModule } from 'mobx-angular';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ControllerPageRoutingModule } from './controller-routing.module';
import { AuthProvider } from 'src/app/providers/auth/auth';
import { LibraryComponentsModule } from 'src/app/components/library/library.components.module';

@NgModule({
    declarations: [
      ControllerPage,
      ChangeResultPopoverComponent
    ],
    imports: [
			IonicModule,
      FormsModule,
      CommonModule,
			ColorPickerModule,
			LibraryComponentsModule,
			GlobalComponentsModule,
			MobxAngularModule,
      ControllerPageRoutingModule
    ],
		providers: [
		]
	})
export class ControllerPageModule { }
