import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ApplicationProvider } from 'src/app/providers/application/application';
import { FirebaseProvider2 } from 'src/app/providers/firebase/firebase';
import { Media, Sponsor } from 'src/app/stores/media.store';

@Component({
  selector: 'app-sponsor-picker',
  templateUrl: './sponsor-picker.component.html',
  styleUrls: ['./sponsor-picker.component.scss'],
})
export class SponsorPickerComponent implements OnInit {

  constructor(
    public  changeDetectorRef: ChangeDetectorRef,
    public  fbProvider: FirebaseProvider2,
    private modalCtrl: ModalController,
    private applicationProvider: ApplicationProvider
  ) { }

  ngOnInit() {}

  dismiss() {
    this.modalCtrl.dismiss(null);
  }

  selectSponsor(sponsor: Sponsor) {
    this.applicationProvider.confirmDialog(`Do you really want to select <b>${sponsor.name}</b> as the current sponsor?`, () => {
      this.modalCtrl.dismiss(sponsor);
    });
  }

  sponsorLogo(sponsor: Sponsor): Media {
    return this.fbProvider.libraryStore.filter(image => {
      if (image.isImage && image.id === sponsor.logo) {
        return image;
      }
    }).reduce((_, image) => image, null);
  }

}
