/* Ionic & Angular core imports */
import { Component, ChangeDetectionStrategy, ChangeDetectorRef, OnInit } from '@angular/core';

/* Types & interfaces */
import { Media } from '../../../stores/media.store';
import { ModalController, NavParams } from '@ionic/angular';
import { FirebaseProvider2 } from 'src/app/providers/firebase/firebase';
import { toJS } from 'mobx';

@Component({
  selector: 'app-media-editor',
  templateUrl: 'media-editor.html',
  styleUrls: ['media-editor.scss'],
  // TODO: Change to OnPush
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MediaEditorComponent implements OnInit {

  media: Media;

  constructor(
    private viewCtrl: ModalController,
    public fbProvider: FirebaseProvider2,
    public changeDetectorRef: ChangeDetectorRef,
    params: NavParams,
  ) {
    fbProvider.initFirebase();
    // console.log(toJS(Object.assign({}, params.get('media'))))
    Object.assign({}, params.get('media'));
  }

  ngOnInit() {
    this.fbProvider.libraryUpdated.subscribe(
      () => this.detectChanges()
    );
  }

  /**
   * Function will close modal
   */
  dismiss(save: boolean) {
    if (save) {
      if (this.media.name == '(new media)') {
        this.fbProvider.removeMedia(0, this.media);
      } else {
        this.fbProvider.updateLibraryStore(this.media);
      }
    } else {
      if (this.media.name == '(new media)') {
        this.fbProvider.removeMedia(0, this.media);
      }
    }
    this.viewCtrl.dismiss();
  }

  detectChanges() {
    this.changeDetectorRef.detectChanges();
  }
}
