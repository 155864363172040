import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { HomePage } from './home.page';

import { HomePageRoutingModule } from './home-routing.module';
import { InitialPage } from '../pages/initial/initial';
import { InitialPageModule } from '../pages/initial/initial.module';
// import { ApplicationProvider } from '../providers/application/application';
import { ApplicationConfig } from 'src/environments/environment';
import { AuthProvider } from '../providers/auth/auth';
import { AuthPageModule } from '../pages/auth/auth.module';
import { TabsPage } from '../pages/tabs/tabs';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    AuthPageModule
    // HomePageRoutingModule
  ],
  declarations: [HomePage],
  entryComponents: [
    InitialPage,
  ]
})
export class HomePageModule {}
