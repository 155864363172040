import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EventsPage } from '../events/events.page';
import { PlayersPage } from '../players/players.page';
import { SponsorTiersPage } from '../sponsor-tiers/sponsor-tiers.page';
import { SponsorsPage } from '../sponsors/sponsors.page';
import { LibraryPage } from './library';


const routes: Routes = [
  {
    path: '',
    component: LibraryPage,
  },
  {
    path: 'sponsors',
    component: SponsorsPage
  },
  {
    path: 'sponsor-tiers',
    component: SponsorTiersPage
  },
  {
    path: 'players',
    component: PlayersPage
  },
  {
    path: 'events',
    component: EventsPage
  }
];


@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class LibraryPageRoutingModule { }
