/* Ionic & Angular core imports */
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';

/* Pages */
import { CollectionsPage } from './collections';

/* Components */
import { CollectionsComponentsModule } from '../../components/collections/collections.components.module';
import { MobxAngularModule } from 'mobx-angular';
import { CommonModule } from '@angular/common';
import { CollectionsPageRoutingModule } from './collections-routing.module';
import { AuthProvider } from 'src/app/providers/auth/auth';
import { GlobalComponentsModule } from 'src/app/components/global/global.components.module';

@NgModule({
  declarations: [
    CollectionsPage,
  ],
  imports: [
    IonicModule,
    CollectionsComponentsModule,
    MobxAngularModule,
    CommonModule,
    CollectionsPageRoutingModule,
    GlobalComponentsModule
  ]
})
export class CollectionsPageModule { }
