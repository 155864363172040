/* Ionic & Angular core imports */
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NavController, MenuController } from '@ionic/angular';

/* Pages */
// import { AuthPage } from '../auth/auth';
// import { SitesPage } from '../sites/sites';

/* Types and interfaces */
import { User } from '../../stores/user.store';

/* Providers */
// import { FirebaseProvider } from '../../providers/firebase/firebase';
import { observable, computed, action } from 'mobx-angular';
import { intercept, observe } from 'mobx';
import { FirebaseProvider2 } from 'src/app/providers/firebase/firebase';
import { ApplicationProvider } from 'src/app/providers/application/application';


@Component({
    selector   : 'app-page-initial',
    templateUrl: 'initial.html',
    styleUrls: ['initial.scss'],
})
export class InitialPage implements OnInit {
  @observable user: User;

  constructor(
    private navCtrl: NavController,
    // private _fbProvider : FirebaseProvider,
    public 	fbProvider: FirebaseProvider2,
    private appProvider: ApplicationProvider,
            menuCtrl: MenuController,
  ) {
    menuCtrl.swipeGesture(true);
  }

  async ngOnInit() {
    await this.loadCurrentUser();
  }

  @action async loadCurrentUser() {
    await this.appProvider.presentLoadingDefault();
    try {
      this.user = await this.fbProvider.getCurrentUser();
    } catch (error) {
      console.log(error);
    }
    await this.appProvider.dismissLoading();
  }

  openAuthPage() {
    this.navCtrl.navigateRoot('auth');
      // this._navCtrl.setRoot(AuthPage, null, { animation: 'ios-transition' });
  }

  openSitesPage() {
    this.fbProvider.initFirebase();
    this.navCtrl.navigateRoot('sites');
      // this._navCtrl.setRoot(SitesPage, null, { animation: 'ios-transition' });
  }
}
