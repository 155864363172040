import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { HomePage } from './home/home.page';
import { AuthPage } from './pages/auth/auth';
import { CollectionsPage } from './pages/collections/collections';
import { SettingsPage } from './pages/settings/settings';
import { SitesPage } from './pages/sites/sites';
import { SponsorsPage } from './pages/sponsors/sponsors.page';
import { TabsPage } from './pages/tabs/tabs';

const routes: Routes = [
  // {
    // path: '',
    // loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  // },
  {
    path: '',
    component: HomePage
  },
  {
    path: 'sites',
    component: SitesPage
  },
  {
    path: 'auth',
    component: AuthPage
  },
  {
    path: 'overview',
    loadChildren: () => import('./pages/tabs/tabs.module').then( m => m.TabsPageModule)
  },
  {
    path: 'collections',
    component: CollectionsPage
  },
  {
    path: 'settings',
    component: SettingsPage
  },
  {
    path: 'players',
    loadChildren: () => import('./pages/players/players.module').then( m => m.PlayersPageModule)
  },
  {
    path: 'team',
    loadChildren: () => import('./pages/team/team.module').then( m => m.TeamPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, enableTracing: false })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
