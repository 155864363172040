/* Ionic & Angular core imports */
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { AuthPage } from './auth';

@NgModule({
	declarations: [
		AuthPage,
	],
	imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule
		// IonicPageModule.forChild(AuthPage)
	]
})
export class AuthPageModule { }
