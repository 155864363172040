import { UUID } from 'angular2-uuid';
import * as moment from 'moment';

export enum Position {
  DEFENSE = 'defense',
  FORWARD = 'forward',
  WING = 'wing',
  CENTER = 'center',
  GOALKEEPER = 'goalkeeper',
  MAIN_COACH = 'main-coach',
  ASS_COACH = 'assistant-coach'
}

export class Player {
  id = UUID.UUID();
  siteId = '';
  firstName = '';
  lastName = '(New Player)';
  number: number = 0;
  photoFace: string;
  photoStance: string;
  video: string;
  sponsor: string;
  position: Position;

  constructor(element: any = null, siteId: string) {
    this.siteId = siteId;
    if (element) {
      for (const key in element) {
        if (this.hasOwnProperty(key)) {
          this[key] = element[key];
        }
      }
    }
  }
}
