/* Other */
import { UUID } from 'angular2-uuid';

/* types & interfaces */
import { Media, AutoplayConfig } from './media.store';
import { Result } from './result.store';

export class Screen {
    id  : string = null;
    name: string = null;

    width : number = 0;
    height: number = 0;

    top : number = 0;
    left: number = 0;

    zIndex: number = 1;

    visible          : boolean = true;
    brightness       : number  = 100;
    background       : string  = '#ffffff';
    showNetworkStatus: boolean = false;
    showDebugInfo    : boolean = false;

    mediaStatus       : boolean         = true;
    showLogo          : boolean         = true;
    currentMedia      : Media           = null;
    autoplay          : AutoplayConfig  = null;
    isResultScreen    : boolean         = false;
    result            : Result          = new Result();
    scoreboardSettings: ScoreboardSettings = new ScoreboardSettings();

    constructor(element: any = null) {
        if (!element) {
            this.id = UUID.UUID();
            return;
        }

        for (let key in element) {
            if (this.hasOwnProperty(key)) {
                this[key] = element[key];
            }
        }
    }
}

export class ScoreboardSettings {
  showScoreboard    : boolean = true;
  showGameTime      : boolean = true; // Shows or hides all time related stuff; period, game time, penalties
  shrinkBackground  : boolean = true;
  matchId           : string  = '';
}
