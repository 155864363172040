/* Ionic & Angular core imports */
import { Injectable } from '@angular/core';
import { AlertController, ToastController, LoadingController, LoadingOptions } from '@ionic/angular';

/* Config */
import { ApplicationConfig } from 'src/environments/environment';
import { OverlayBaseController } from '@ionic/angular/util/overlay';
import { Mutex } from 'async-mutex';

@Injectable({
  providedIn: 'root'
})
export class ApplicationProvider {
  private loading: HTMLIonLoadingElement;
  private isLoadingPresented = false;
  private loadingPresenting = false;

  private presenterMutex: Mutex;

  constructor(
    private loadingCtrl: LoadingController,
    private toastCtrl: ToastController,
    private alertCtrl: AlertController,
    private config: ApplicationConfig
  ) {
    console.log("Application Provider: Created                    ")
    this.presenterMutex = new Mutex();
  }

  /**
   * @param text
   * Function presents an overlay with a loading information
   * @param text Text to be displayed on loading window.
   * No param will display default text
   */
  public async presentLoadingDefault(text?: string): Promise<any> {
    let promise;
    await this.presenterMutex
      .acquire()
      .then(async (release) => {
        if (!this.isLoadingPresented && !this.loadingPresenting) {
        this.loadingPresenting = true;
        this.loading = await this.loadingCtrl.create({
          message: text ? text: 'Please wait...'
        });
        // console.trace()
        promise = await this.loading.present();
        this.isLoadingPresented = true;
      }
      release();
    });
    return promise;
  }

  /**
   * @param msg Text to be displayed as a toast after dismissing
   * the loading component.
   * Function removes the overlay with loading information
   */
  public async dismissLoading(msg?: string): Promise<any> {
    let promise;
    if (msg) {
      const toast = await this.toastCtrl.create({
        message : msg,
        duration: this.config.applicationConfig.infoMessageDurationSec * 1000,
        position: this.config.applicationConfig.infoMessagePosition as ('top' | 'bottom' | 'middle')
      });

      if (!this.isLoadingPresented) {
        return toast.present();
      } else {
        this.isLoadingPresented = false;
        if (this.loading) {
          this.loading.dismiss().then(() => toast.present());
        }
      }
    } else {
      await this.presenterMutex
        .acquire()
        .then(async (release) => {
          if (this.isLoadingPresented && this.loadingPresenting) {
            this.isLoadingPresented = false;
            this.loadingPresenting = false;
            if (this.loading) {
              promise = await this.loading.dismiss().then(() => {
                delete this.loading;
                return new Promise((resolve, reject) => { resolve(true); })
              });
            }
          }
          release();
        }
      );
    }
    return promise;
  }

  /**
   * @param msg Text to be displayed as a toast after dismissing
   * the loading component.
   * Function shows information for the user
   */
  public async showInformation(msg: string): Promise<any> {
    const toast = await this.toastCtrl.create({
      message : msg,
      duration: this.config.applicationConfig.infoMessageDurationSec * 1000,
      position: this.config.applicationConfig.infoMessagePosition as ('top' | 'bottom' | 'middle')
    });
    return toast.present();
  }

  public displayDebugInformation(info: any): void {
    if (this.config.mode === 'development') {
      console.warn(info);
    }
  }

  public async confirmDialog(msg: string, callback: any): Promise<any> {
    const alert = await this.alertCtrl.create({
      header  : 'Confirmation required',
      message: msg,
      buttons: [{
        text: 'No'
      }, {
        text   : 'Yes',
        handler: () => {
          callback();
        }
      }]
    });
    return alert.present();
  }

  public reorderArray(array: any[], indexes: { from: number; to: number }): any[] {
    const element = array[indexes.from];
    array.splice(indexes.from, 1);
    array.splice(indexes.to, 0, element);
    return array;
  }
}
