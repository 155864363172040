/* Ionic & Angular core imports */
import { Component, ChangeDetectorRef, Input } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { Media } from 'src/app/stores/media.store';

@Component({
    selector   : 'app-media-adder',
    templateUrl: 'media-adder.html',
    styleUrls: ['media-adder.scss']
})
export class MediaAdderPopoverComponent {

  @Input() library: Media[];

  currentMedia: Media[];
  selectedMedia: Media[] = [];

  constructor(
    private viewCtrl: ModalController,
    private detector: ChangeDetectorRef,
    private navParams: NavParams
  ) {

    this.library = this.navParams.get('library');
    this.currentMedia = this.navParams.get('currentMedia');

    console.log(this.currentMedia, this.library);
  }

  isMediaAdded(media: Media): boolean {
    return this.selectedMedia.indexOf(media) > -1;
  }

  countMedia(media: Media): number {
    let count = 0;
    this.currentMedia.forEach(m => {
      if (m === media) {
        count++;
      }
    });

    this.selectedMedia.forEach(m => {
      if (m === media) {
        count++;
      }
    });

    return count;
  }

  toggleMedia(media: Media): void {
    const index = this.selectedMedia.indexOf(media);
    if (index < 0) {
      this.selectedMedia.push(media);
    } else {
      this.selectedMedia.splice(index, 1);
    }
  }

  close() {
    this.viewCtrl.dismiss(null);
  }

  save() {
    this.viewCtrl.dismiss({
      addedMedia: this.selectedMedia
    });
  }

  start() {
    console.log('MediaAdderPopover: Start');
  }
}
