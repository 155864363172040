/* Ionic & Angular core imports */
import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

/* Types & interfaces */
import { Media } from '../../../stores/media.store';

/* Providers */
import { ApplicationProvider } from '../../../providers/application/application';
import { FirebaseProvider2 } from 'src/app/providers/firebase/firebase';

/* Components*/
import { MediaPreviewComponent } from '../media-preview/media-preview';
import { observable } from 'mobx';

@Component({
    selector   : 'app-media-picker',
    templateUrl: 'media-picker.html',
    styleUrls: ['media-picker.scss']
})
export class MediaPickerComponent implements OnInit, OnDestroy {

  mediaType: string;

  constructor(
    public  changeDetectorRef: ChangeDetectorRef,
    public  fbProvider: FirebaseProvider2,
    private applicationProvider: ApplicationProvider,
    private modalCtrl: ModalController,
    params: NavParams
    ) {
      // loading should be dismissed
      // when settings are loaded from database
      // await this.applicationProvider.presentLoadingDefault();
      this.mediaType = params.get('mediaType');
    }

    // ngOnInit is required as if this is not implemented,
    // then it is not called and we create dynamically ngOnInit
    // function in AutoAsync decorator
    ngOnInit() { }
    ngOnDestroy() { }

    /**
     * Function will close modal
     */
    dismiss() {
        this.modalCtrl.dismiss(null);
    }

    @observable get mediaStore(): Media[] {
      if (this.mediaType != 'all') {
        return this.fbProvider.libraryStore.filter(media => {
          switch (this.mediaType) {
            case 'image':
              if (media.isImage) {
                return media;
              }
              break;
            case 'video':
              if (!media.isImage) {
                return media;
              }
              break;
            default:
              return media;
          }
        })
      } else {
        return this.fbProvider.libraryStore;
      }
    }

    /**
     * Function that is called when data is being loaded
     * for the first time
     */
    async dismissLoading() {
        // dismiss loading message and overlay
        await this.applicationProvider.dismissLoading();
    }

    async previewMedia(media: Media) {
        const modal = await this.modalCtrl.create({
          component: MediaPreviewComponent,
          componentProps: media
        });
        await modal.present();
    }

    selectMedia(media: Media) {
        // confirm action
        this.applicationProvider.confirmDialog(`Do you really want to change screen media to <b>${media.name}</b>?`, () => {
            this.modalCtrl.dismiss(media);
        });
    }
}
