/* Angular & Ionic core imports */
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ApplicationProvider } from 'src/app/providers/application/application';
import { FirebaseProvider2 } from 'src/app/providers/firebase/firebase';
import { User } from 'src/app/stores/user.store';

@Component({
    selector   : 'app-header',
    templateUrl: 'header.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit {

  user: User;

  constructor(
    public fbProvider: FirebaseProvider2,
    public changeDetectorRef: ChangeDetectorRef,
    private applicationProvider: ApplicationProvider) {
    // fbProvider.getCurrentUser().then(() => {
    //   this.currentUser = fbProvider.currentUser;
    // });
    // this.fbProvider.getCurrentUser();
  }

  // isDeveloper(): boolean {
  //   if (this.currentUser != null && this.currentUser != undefined && this.currentUser.developer) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }

  async updateUser(checked: boolean) {
    await this.applicationProvider.presentLoadingDefault();
    const user: User = await this.fbProvider.getCurrentUser();
    user.godmode = checked;
    await this.fbProvider.updateCurrentUser(user);
    await this.applicationProvider.dismissLoading();
  }

  async ngOnInit() {
    await this.fbProvider.initFirebase(this.changeDetectorRef);
    this.fbProvider.usersUpdated.subscribe(async () => {
      if (this.fbProvider.currentUser) {
        await this.applicationProvider.dismissLoading();
        if (this.fbProvider.currentUser != this.user) {
          this.changeDetectorRef.detectChanges();
          this.user = this.fbProvider.currentUser;
        }
      } else {
        await this.applicationProvider.presentLoadingDefault();
      }
    });
  }
}
