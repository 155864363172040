/* Ionic & Angular core imports */
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';

/* Pages */
import { SettingsPage } from './settings';

/* Components */
import { MobxAngularModule } from 'mobx-angular';
import { CommonModule } from '@angular/common';
import { SettingsComponentsModule } from 'src/app/components/settings/settings.components.module';
import { FormsModule } from '@angular/forms';
import { GlobalComponentsModule } from 'src/app/components/global/global.components.module';
import { SettingsPageRoutingModule } from './settings-routing.module';

@NgModule({
  declarations: [
    SettingsPage,
  ],
  imports: [
    IonicModule,
    SettingsComponentsModule,
    MobxAngularModule,
    CommonModule,
    FormsModule,
    GlobalComponentsModule,
    SettingsPageRoutingModule
  ],
})
export class SettingsPageModule { }
