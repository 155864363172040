export class User {
    id:     string = null;
    name:   string = null;
    email:  string = null;
    developer: boolean = null;
    godmode: boolean = null;

    constructor(elements: any = null) {
        if (elements) {
            Object.keys(elements).forEach(element => {
              if (this.hasOwnProperty(element)) {
                    this[element] = elements[element];
                }
            });
        }
    }
}
