/* Angular & Ionic core imports */
import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { NavParams } from '@ionic/angular';

/* Types & Interfaces */
import { Screen } from '../../stores/screen.store';
import { Site } from '../../stores/sites.store';

/* Providers */
import { ApplicationProvider } from '../../providers/application/application';
import { Media } from '../../stores/media.store';
import { FirebaseProvider2 } from 'src/app/providers/firebase/firebase';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-page-settings',
  templateUrl: 'settings.html',
  styleUrls: ['settings.scss']
})
export class SettingsPage implements OnInit, OnDestroy {

  selectedScreenIdx = 0;
  site: Site;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    public fbProvider: FirebaseProvider2,
    private applicationProvider: ApplicationProvider,
    private activatedRoute: ActivatedRoute,
  ) {
    // this.site = params.get('site');
    this.activatedRoute.queryParams.subscribe(params => {
      this.site = params.site;
    });
    this.setup();
    // loading should be dissmissed
    // when settings are loaded from database
    // await this._applicationProvider.presentLoadingDefault();
  }

  async setup() {
    await this.fbProvider.initFirebase();
    // await this.fbProvider.getCurrentUser();
    // this.site = this.fbProvider.availableSites
    this.fbProvider.sitesUpdated.subscribe(() => {
      if (JSON.stringify(this.site) !== JSON.stringify(this.fbProvider.currentSite)) {
        this.site = this.fbProvider.currentSite;
        this.changeDetectorRef.detectChanges();
      }
    });
  }

  // ngOnInit is required as if this is not implemented,
  // then it is not called and we create dynamically ngOnInit
  // function in AutoAsync decorator
  ngOnInit() { }
  ngOnDestroy() { }

  /**
   * Function that is called when data is being loaded
   * for the first time
   */
  dismissLoading() {
    // dismiss loading message and overlay
    this.applicationProvider.dismissLoading();
  }

  /**
   * Callback triggered when screens is updated. We have to
   * make sure that selected screen (selected tab) exists.
   */
  onScreenUpdate() {
    if (!this.fbProvider.listOfScreens || this.selectedScreenIdx >= this.fbProvider.listOfScreens.length) {
      this.selectedScreenIdx = 0;
    }
  }

  /**
   * Function triggered to add new screen
   */
  addScreen() {
    // confirm action
    this.applicationProvider.confirmDialog('Do you really want to add new screen?', () => {
      // create new screen
      const screen = new Screen();

      // setup default name
      screen.name = `New screen (${this.fbProvider.listOfScreens ? this.fbProvider.listOfScreens.length + 1 : 1})`;

      // when there are no screens than object returned from db
      // is null even if we change it to the array we do not set Proxy
      // for it so we do not track changes in array.
      // In this case we have to overwrite the property.
      if (!this.fbProvider.listOfScreens) {
        // overwrite completely property
        this.fbProvider.updateScreen(screen, 0);
      } else {
        // add screen to the array.
        // this will automatically synchronize with database
        this.fbProvider.updateScreen(screen, this.fbProvider.listOfScreens.length);
      }

      // select new screen
      this.selectedScreenIdx = this.fbProvider.listOfScreens.length - 1;
    });
  }

  /**
   * Triggered when remove screen is clicked
   */
  removeSelectedScreen(screen: Screen) {

    // confirm action
    this.applicationProvider.confirmDialog('Do you really want to remove this screen?', () => {
      // this.screens.splice(this.selectedScreenIdx, 1);
      this.fbProvider.removeScreen(screen);
      this.selectedScreenIdx = 0;
    });
  }

  /**
   * Due to the bug in IONIC 3 that makes the tabs not working
   * properly when generated by *NgFor (https://github.com/driftyco/ionic/issues/6923)
   * we have to manually mak the change in the variable that keeps value of
   * the selected tab.
   *
   * @param index index of selected tab
   * @param event js event
   */
  public setOption(index, event, previousIndex) {
    // if(this.fbProvider.listOfScreens[previousIndex] != null) {
    //   this.fbProvider.updateScreen(this.fbProvider.listOfScreens[previousIndex], previousIndex)
    // }
    if (this.fbProvider.listOfScreens[index] != null) {
      this.selectedScreenIdx = index;

      // note you have to use "tap" or "click" - if you
      // bind to "ionSelected" you don't get the "target" property
      const segments = event.target.parentNode.children;
      const len = segments.length;
      for (let i = 0; i < len; i++) {
        segments[i].classList.remove('segment-activated');
      }
      event.target.classList.add('segment-activated');
    }
  }
}
