/* Ionic & Angular core imports */
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';

/* Pages */
import { LibraryPage } from './library';

/* Uploader */
import { FileUploadModule } from 'ng2-file-upload';

/* Components */
import { LibraryComponentsModule } from '../../components/library/library.components.module';
import { GlobalComponentsModule } from '../../components/global/global.components.module';
import { MobxAngularModule } from 'mobx-angular';
import { LibraryPageRoutingModule } from './library-routing.module';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

/* Providers */


@NgModule({
  declarations: [
    LibraryPage,
  ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    LibraryPageRoutingModule,
    MobxAngularModule,
    FileUploadModule,
    LibraryComponentsModule,
    GlobalComponentsModule,
  ],
  providers: [
  ]
})
export class LibraryPageModule { }
