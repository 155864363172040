import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ApplicationProvider } from './providers/application/application';
import { FirebaseProvider2 } from './providers/firebase/firebase';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class AppComponent implements OnInit {
  constructor(
    private fbProvider: FirebaseProvider2,
    private applicationProvider: ApplicationProvider) {}

  async ngOnInit() {
    await this.applicationProvider.presentLoadingDefault();
    await this.fbProvider.initFirebase();
    await this.applicationProvider.dismissLoading();
  }
}
