import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { SponsorsPageRoutingModule } from './sponsors-routing.module';

import { SponsorsPage } from './sponsors.page';
import { MobxAngularModule } from 'mobx-angular';
import { GlobalComponentsModule } from 'src/app/components/global/global.components.module';
import { SponsorsComponentsModule } from 'src/app/components/sponsors/sponsors.components.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    SponsorsPageRoutingModule,
    MobxAngularModule,
    GlobalComponentsModule,
    SponsorsComponentsModule
  ],
  declarations: [
    SponsorsPage
  ]
})
export class SponsorsPageModule {}
