/* Ionic & Angular core imports */
import { Component } from '@angular/core';
import { NavParams, ModalController, PopoverController, ViewWillLeave } from '@ionic/angular';

/* Types and interfaces */
import { Site } from '../../../stores/sites.store';
import { User } from '../../../stores/user.store';

/* Providers */
import { ApplicationProvider } from '../../../providers/application/application';
import { FirebaseProvider2 } from 'src/app/providers/firebase/firebase';

@Component({
  selector: 'app-site-editor',
  templateUrl: 'site-editor.html',
  styleUrls: ['site-editor.scss']
})
export class SiteEditorComponent implements ViewWillLeave {

  // selected site
  site: Site;
  // list of all users in system
  allUsers;

  constructor(
    private modalCtrl: ModalController,
    private fbProvider: FirebaseProvider2,
    private appProvider: ApplicationProvider,
    params: NavParams,
  ) {
    this.site = params.get('site');
    this.fbProvider.dbRootNode = '/';
    this.fbProvider.setDBRootNodeRef();
    // if (this.site.ownerId != fbProvider.UserId) {
    //   this._viewCtrl.onWillDismiss().then(() => {
    //     this.fbProvider.updateSite(this.site);
    //   });
    //   this.loadAvailableUsers();

    // } else {
    //   this._viewCtrl.onWillDismiss().then(() => {
    //       this.fbProvider.updateSite(this.site);
    //   });

    //   this.loadAvailableUsers();
    // }
    this.loadAvailableUsers();
  }

  // users in site object is not an array so we have to
  // convert it to array
  get siteUsers(): string[] {
    const result: string[] = [];

    if (this.allUsers) {
      for (const userId in this.site.users) {
        if (!!this.allUsers[userId]) {
          result.push(userId);
        }
      }
    }

    return result;
  };


  get availableUsers(): User[] {
    const result: User[] = [];
    if (this.allUsers) {
      for (const userId in this.allUsers) {
        if (this.siteUsers.indexOf(userId) === -1) {
          result.push(this.allUsers[userId]);
        }
      }
    }

    return result;
  }

  ionViewWillLeave() {
    this.fbProvider.updateSite(this.site);
    // this.loadAvailableUsers();
  }

  async addAccess(userId) {
    await this.appProvider.presentLoadingDefault();
    try {
      this.site.users[userId.detail.value] = true;
      await this.fbProvider.updateSite(this.site);
    } catch (e) {
      this.appProvider.displayDebugInformation(e);
      this.appProvider.showInformation('Error while setting up access for user');
    }

    await this.appProvider.dismissLoading();
  }

  removeAccess(userId) {
    this.appProvider.confirmDialog(`Are you sure you want to remove <b>
        ${this.allUsers[userId].name}</b> access to this site?`, async () => {
      await this.appProvider.presentLoadingDefault();

      try {
        delete this.site.users[userId];
        await this.fbProvider.updateSite(this.site);
      } catch (e) {
        this.appProvider.displayDebugInformation(e);
        this.appProvider.showInformation('Error while setting up access for user');
      }

      await this.appProvider.dismissLoading();
    });
  }

  async loadAvailableUsers() {

    await this.appProvider.presentLoadingDefault();

    try {
      const users = await this.fbProvider.availableUsers;
      this.allUsers = {};

      users.forEach(user => {
        this.allUsers[user.id] = new User(user);
      });

    } catch (e) {
      this.appProvider.displayDebugInformation(e);
      this.appProvider.showInformation('Error while loading list of users');
    }

    await this.appProvider.dismissLoading();
  }

  /**
   * Function will close modal
   */
  async dismiss(save: boolean) {
    await this.modalCtrl.dismiss();
  }
}
