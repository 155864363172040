/* Ionic & Angular core imports */
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';

/* Firebase */
import * as firebase from 'firebase/app';
import 'firebase/auth';

@Injectable({
  providedIn: 'root'
})
export class AuthProvider {

  constructor(
    private navCtrl: NavController,
    private router: Router) {

  }

  get uid() {
    return localStorage.getItem('uid');
  }
  set uid(v) {
    localStorage.setItem('uid', v);
  }

  loginUser(email: string, password: string): Promise<firebase.auth.UserCredential> {
    return firebase.auth().signInWithEmailAndPassword(email, password);
  }

  async signupUser(email: string, password: string): Promise<firebase.auth.UserCredential> {
    try {
      const newUser: firebase.auth.UserCredential = await firebase
        .auth()
        .createUserWithEmailAndPassword(email, password);

      return newUser;
    } catch (error) {
      throw error;
    }
  }

  resetPassword(email: string): Promise<void> {
    return firebase.auth().sendPasswordResetEmail(email);
  }

  logoutUser(): Promise<void> {
    localStorage.removeItem('uid');
    localStorage.removeItem('siteId');
    if (this.router.url !== '/') {
      this.navCtrl.navigateRoot('auth');
    }
    return firebase.auth().signOut();
  }
}
