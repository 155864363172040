import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { action, computed, observable, runInAction } from 'mobx';
import { FirebaseProvider2 } from 'src/app/providers/firebase/firebase';
import { Media, SponsorTier } from 'src/app/stores/media.store';
import { ApplicationConfig } from 'src/environments/environment';
import { OverlayEventDetail } from '@ionic/core';
import { MediaPickerComponent } from '../../library/media-picker/media-picker';

@Component({
  selector: 'app-sponsor-tier-editor',
  templateUrl: './sponsor-tier-editor.component.html',
  styleUrls: ['./sponsor-tier-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SponsorTierEditorComponent implements OnInit {

  sponsorTier: SponsorTier;

  externalServerAddress: string;

  @observable logo: Media;

  constructor(
    public fbProvider: FirebaseProvider2,
    public changeDetectorRef: ChangeDetectorRef,
    private modalCtrl: ModalController,
    appConfig: ApplicationConfig,
    params: NavParams
  ) {
    fbProvider.initFirebase();

    this.sponsorTier = Object.assign({}, params.get('sponsorTier'));
    this.externalServerAddress = appConfig.externalLibrary.viewURL;
  }

  ngOnInit() {
    this.fbProvider.sponsorsUpdated.subscribe(
      () => this.detectChanges()
    );
  }

  @action async addSponsorTierLogo() {
    const modal = await this.modalCtrl.create({
      component: MediaPickerComponent,
      componentProps: {
        mediaType: 'image'
      }
    });

    await modal.present();
    modal.onWillDismiss().then((overlayEvent: OverlayEventDetail<Media>) => {
      if (overlayEvent.data) {
        runInAction(() => {
          this.sponsorTier.logo = overlayEvent.data.id;
          this.logo = overlayEvent.data;
        });
        this.detectChanges();
      }
    });
  }

  @computed get sponsorTierLogo(): Media {
    const image = this.fbProvider.libraryStore.filter(image => {
      if (image.isImage && image.id === this.sponsorTier.logo) {
        return image;
      }
    }).reduce((_, image) => image, null);

    this.logo = image;

    return image;
  }

  detectChanges() {
    this.changeDetectorRef.detectChanges();
  }

  dismiss(save: boolean) {
    if (save) {
      if (this.sponsorTier.name == '(New Sponsor tier)') {
        this.fbProvider.removeSponsorTier(this.sponsorTier);
      } else {
        this.fbProvider.updateSponsorTier(this.sponsorTier);
      }
    } else {
      if (this.sponsorTier.name == '(New Sponsor tier)') {
        this.fbProvider.removeSponsorTier(this.sponsorTier);
      }
    }
    this.modalCtrl.dismiss();
  }
}
