import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { IonList, ModalController } from '@ionic/angular';
import { SponsorTierEditorComponent } from 'src/app/components/sponsors/sponsor-tier-editor/sponsor-tier-editor.component';
import { ApplicationProvider } from 'src/app/providers/application/application';
import { FirebaseProvider2 } from 'src/app/providers/firebase/firebase';
import { SponsorTier } from 'src/app/stores/media.store';
import { Site } from 'src/app/stores/sites.store';

@Component({
  selector: 'app-sponsor-tiers',
  templateUrl: './sponsor-tiers.page.html',
  styleUrls: ['./sponsor-tiers.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SponsorTiersPage implements OnInit {


  @ViewChild(IonList) list: IonList;

  constructor(
    public changeDetectorRef: ChangeDetectorRef,
    public fbProvider: FirebaseProvider2,
    private applicationProvider: ApplicationProvider,
    private modalCtrl: ModalController
  ) {
    this.setup();
  }

  async setup() {
    await this.fbProvider.initFirebase(this.changeDetectorRef);
    await this.fbProvider.getCurrentUser();
  }

  async addNewSponsorTier() {
    await this.list.closeSlidingItems();
    if (this.fbProvider.currentSite != null) {
      this.fbProvider.updateSponsorTier(new SponsorTier(null, this.fbProvider.currentSite.id))
        .then(async () => {
          const modal = await this.modalCtrl.create({
            component: SponsorTierEditorComponent,
            componentProps:{
              sponsorTier : this.fbProvider.siteSponsorTiers[this.fbProvider.siteSponsorTiers.length - 1]
            }
          });

          modal.onWillDismiss().then(() => {
            this.detectChanges();
          });

          modal.present();
        });
    }
  }

  async editSponsorTier(sponsorTier: SponsorTier) {
    await this.list.closeSlidingItems();

    const modal = await this.modalCtrl.create({
      component: SponsorTierEditorComponent,
      componentProps: {
        sponsorTier: Object.assign({}, sponsorTier)
      }
    });

    modal.onWillDismiss().then(() => {
      this.detectChanges();
    });

    modal.present();
  }

  getSponsorTierLogo(sponsorTier: SponsorTier) {
    const image = this.fbProvider.libraryStore.filter(image => {
      if (image.isImage && image.id == sponsorTier.logo) {
        return image;
      }
    }).reduce((_, image) => image, null);

    return image ? image.thumbnail : '';
  }

  ngOnInit() {
  }

  detectChanges() {
    this.changeDetectorRef.detectChanges();
  }

}
