import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { FirebaseProvider2 } from './providers/firebase/firebase';
import { InitialPage } from './pages/initial/initial';
import { InitialPageModule } from './pages/initial/initial.module';
import { ApplicationConfig } from 'src/environments/environment';
import { SitesPageModule } from './pages/sites/sites.module';
import { AuthProvider } from './providers/auth/auth';
import { CommonModule } from '@angular/common';
import { ApplicationProvider } from './providers/application/application';
import { HomePageModule } from './home/home.module';
import { GlobalComponentsModule } from './components/global/global.components.module';
import { LibraryPageModule } from './pages/library/library.module';
import { ControllerPageModule } from './pages/controller/controller.module';
import { CollectionsPageModule } from './pages/collections/collections.module';
import { Storage } from '@ionic/storage';
import { TabsPage } from './pages/tabs/tabs';
import { FormsModule } from '@angular/forms';
import { IonicStorageModule } from '@ionic/storage-angular';
import { SettingsPageModule } from './pages/settings/settings.module';
import { SponsorsPageModule } from './pages/sponsors/sponsors.module';
import { SponsorTiersPageModule } from './pages/sponsor-tiers/sponsor-tiers.module';
import { PlayersPageModule } from './pages/players/players.module';
import { EventsPageModule } from './pages/events/events.module';

@NgModule({
  declarations: [
    AppComponent
  ],
  entryComponents: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(),
    AppRoutingModule,
    InitialPageModule,
    HomePageModule,
    FormsModule,
    SitesPageModule,
    GlobalComponentsModule,
    LibraryPageModule,
    ControllerPageModule,
    SettingsPageModule,
    CollectionsPageModule,
    SponsorsPageModule,
    SponsorTiersPageModule,
    PlayersPageModule,
    EventsPageModule
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    ApplicationProvider,
    AppRoutingModule,
    ApplicationConfig,
    FirebaseProvider2,
    Storage,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
