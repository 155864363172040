import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { IonList, ModalController } from '@ionic/angular';
import { EventEditorComponent } from 'src/app/components/events/event-editor/event-editor.component';
import { ApplicationProvider } from 'src/app/providers/application/application';
import { FirebaseProvider2 } from 'src/app/providers/firebase/firebase';
import { Event } from 'src/app/stores/event.store';

@Component({
  selector: 'app-events',
  templateUrl: './events.page.html',
  styleUrls: ['./events.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventsPage implements OnInit {

  @ViewChild(IonList) list: IonList;

  constructor(
    public changeDetectorRef: ChangeDetectorRef,
    public fbProvider: FirebaseProvider2,
    private applicationProvider: ApplicationProvider,
    private modalCtrl: ModalController
  ) {
  }

  async setup() {
    await this.fbProvider.initFirebase(this.changeDetectorRef);
    await this.fbProvider.getCurrentUser();
  }

  getEventLogo(event: Event) {
    const image = this.fbProvider.libraryStore
      .filter(image => {
        if (image.id == event.quickAccessLogo) {
          return image;
        }
      })
      .reduce((_, image) => image, null);

    return image ? image.thumbnail : '';
  }

  async addNewEvent() {
    await this.list.closeSlidingItems();
    if (this.fbProvider.currentSite != null) {
      this.fbProvider.updateEvent(new Event(null, this.fbProvider.currentSite.id))
        .then(async () => {
          const modal = await this.modalCtrl.create({
            component: EventEditorComponent,
            componentProps: {
              event: this.fbProvider.siteEvents[this.fbProvider.siteEvents.length - 1]
            }
          });

          modal.onWillDismiss().then(() => {
            this.detectChanges();
          });

          modal.present();
        }
      );
    }
  }

  async editEvent(event: Event) {
    await this.list.closeSlidingItems();

    const modal = await this.modalCtrl.create({
      component: EventEditorComponent,
      componentProps: { event: Object.assign({}, event)}
    });
    modal.onWillDismiss().then(() => {
      this.detectChanges();
    });

    await modal.present();
  }

  async removeEvent(event: Event) {
    await this.list.closeSlidingItems();
    const currentEvent = this.fbProvider.siteEvents
      .filter(event => {
        if (event.id == event.id) {
          return event;
        }
      })
      .reduce((_, event) => event, null);

    this.applicationProvider.confirmDialog(
      `Do you really want to remove event <b>${currentEvent.name}</b>?`,
      () => {
        this.fbProvider.removeEvent(event);

        this.detectChanges();
      }
    );
  }

  detectChanges() {
    this.changeDetectorRef.detectChanges();
  }

  async ngOnInit() {
    await this.setup();
  }

}
