/* Ionic & Angular core imports */
import { Component, ViewEncapsulation } from '@angular/core';
import { NavController, AlertController, MenuController } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

/* Providers */
import { ApplicationProvider } from '../../providers/application/application';
import { AuthProvider } from '../../providers/auth/auth';
// import { FirebaseProvider } from '../../providers/firebase/firebase';

/* Pages */
import { SitesPage } from '../sites/sites';
import { FirebaseProvider2 } from 'src/app/providers/firebase/firebase';

enum AuthMode {
  login = 'login',
  register = 'register'
};

@Component({
    selector   : 'app-page-auth',
    templateUrl: 'auth.html',
    styleUrls: [ 'auth.scss' ],
})
export class AuthPage {

  public loginForm: FormGroup;
  public signUpForm: FormGroup;

  authMode = AuthMode;
  auth = 'login';

  constructor(
    private navCtrl: NavController,
    private appProvider: ApplicationProvider,
    private authProvider: AuthProvider,
    private formBuilder: FormBuilder,
    public  fbProvider: FirebaseProvider2,
    // private _fbProvider  : FirebaseProvider,
    private alertCtrl: AlertController,
            menuCtrl: MenuController

            ) {
    fbProvider.initFirebase();
    this.buildLoginForm();
    this.buildSignUpForm();

    // menuCtrl.swipeEnable(false);
    menuCtrl.swipeGesture(true);
  }

  buildLoginForm() {
    this.loginForm = this.formBuilder.group({
      email: ['', Validators.compose([
        Validators.required,
        Validators.email
      ])],
      password: ['', Validators.compose([
        Validators.required,
        Validators.minLength(6)
      ])]
    });
  }

  buildSignUpForm() {
    this.signUpForm = this.formBuilder.group({
      email: [
        '',
        Validators.compose([Validators.required, Validators.email])
      ],
      name: [
        '',
        Validators.compose([Validators.required])
      ],
      password: [
        '',
        Validators.compose([Validators.required, Validators.minLength(6)])
      ],
      password2: [
        '',
        Validators.compose([Validators.required, Validators.minLength(6)])
      ]
    });
  }

  /**
   * Function triggered to perform login action
   */
  async login() {
    if (!this.loginForm.valid) {
      this.appProvider.displayDebugInformation(
        `Form is not valid yet, current value: ${this.loginForm.value}`
      );
    } else {
      await this.appProvider.presentLoadingDefault('Please wait');

      const email    = this.loginForm.value.email;
      const password = this.loginForm.value.password;

      try {
        const user: firebase.User    = await (await this.authProvider.loginUser(email, password)).user;
              this.authProvider.uid = user.uid;
        await this.appProvider.dismissLoading('Success');
        this.fbProvider.addDBNodeListeners();
        // this._navCtrl.setRoot(SitesPage);
        this.navCtrl.navigateRoot('sites');
      } catch (error) {
        await this.appProvider.dismissLoading(error.message);
      }
    }
  }

  /**
   * Function triggered to perform sign up action
   */
  async signUp(): Promise<void> {
    if (!this.signUpForm.valid) {
        this.appProvider.displayDebugInformation(
          `Form is not valid yet, current value: ${this.signUpForm.value}`
        );
    } else {
      await this.appProvider.presentLoadingDefault('Please wait');

      const email    = this.signUpForm.value.email;
      const password = this.signUpForm.value.password;
      const name     = this.signUpForm.value.name;

      try {
        const user: firebase.User = (await this.authProvider.signupUser(
          email,
          password
        )).user;

        await user.updateProfile({
          photoURL   : null,
          displayName: name
        });

        await this.fbProvider.createUserEntry(user, name);
        await this.appProvider.dismissLoading('Success');

        this.authProvider.uid = user.uid;

        // this._navCtrl.setRoot(SitesPage);
        this.navCtrl.navigateRoot('sites');
      } catch (error) {
        this.appProvider.dismissLoading(error.message);
      }
    }
  }

  /**
   * Function triggered to perform sign up action
   */
  async forgotPassword() {
    const forgot = await this.alertCtrl.create({
      header  : 'Forgot Password?',
      message: 'Enter you email address to send a password reset link',
      inputs : [{
        name       : 'email',
        placeholder: 'Email',
        type       : 'email'
      }],
      buttons: [{
        text: 'Cancel'
      }, {
        text   : 'Send',
        handler: (data) => {
          const control: any = {
            value: data.email
          };
          this.resetPassword(control);
        }
      }]
    });
    await forgot.present();
  }

  async resetPassword(control: any) {
    if (Validators.email(control) === null) {
      await this.appProvider.presentLoadingDefault('Please wait');
      try {
        await this.authProvider.resetPassword(control.value);
        await this.appProvider.dismissLoading('Check your inbox for a password reset link');
        // this._navCtrl.popToRoot();
        this.navCtrl.navigateRoot('');
      } catch (error) {
        this.appProvider.dismissLoading(error.message);
      }
    } else {
      await this.appProvider.showInformation('Email address is incorrect');
    }
  }
}
