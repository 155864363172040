import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { action, computed, observable, runInAction } from 'mobx';
import { FirebaseProvider2 } from 'src/app/providers/firebase/firebase';
import { OverlayEventDetail } from '@ionic/core';
import { ApplicationConfig } from 'src/environments/environment';
import { MediaPickerComponent } from '../../library/media-picker/media-picker';
import { Media, Sponsor } from 'src/app/stores/media.store';
import { Event } from 'src/app/stores/event.store';
import { SponsorPickerComponent } from '../../sponsors/sponsor-picker/sponsor-picker.component';

@Component({
  selector: 'app-event-editor',
  templateUrl: './event-editor.component.html',
  styleUrls: ['./event-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventEditorComponent implements OnInit {

  event: Event;

  externalServerAddress: string;

  @observable logo: Media;
  @observable media: Media;
  @observable sponsor: Sponsor;
  @observable sponsorLogo: Media;

  constructor(
    public fbProvider: FirebaseProvider2,
    public changeDetectorRef: ChangeDetectorRef,
    private modalCtrl: ModalController,
    appConfig: ApplicationConfig,
    params: NavParams
  ) {
    fbProvider.initFirebase();

    this.event = Object.assign({}, params.get('event'));
    this.externalServerAddress = appConfig.externalLibrary.viewURL;
  }

  ngOnInit() {
    this.fbProvider.eventsUpdated.subscribe(
      () => this.detectChanges()
    );
  }

  async addEventLogo() {
    this._addEventMedia('image');
  }

  async addEventMedia() {
    this._addEventMedia('all');
  }

  @action async _addEventMedia(mediaType: string) {
    const modal = await this.modalCtrl.create({
      component: MediaPickerComponent,
      componentProps: { mediaType: mediaType }
    });
    modal.onWillDismiss().then((overlayEvent: OverlayEventDetail<Media>) => {
      if (overlayEvent.data) {
        runInAction(() => {
          switch (mediaType) {
            case 'image':
              this.event.quickAccessLogo = overlayEvent.data.id;
              this.logo = overlayEvent.data;
              break;
            case 'all':
              this.event.media = overlayEvent.data.id;
              this.media = overlayEvent.data;
              break;
          }
        });
        this.detectChanges();
      }
    });
    await modal.present();
  }

  @action async addEventSponsor() {
    const modal = await this.modalCtrl.create({
      component: SponsorPickerComponent
    });
    modal.onWillDismiss().then((overlayEvent: OverlayEventDetail<Sponsor>) => {
      if (overlayEvent.data) {
        runInAction(() => {
          this.event.sponsor = overlayEvent.data.id;
          this.sponsorLogo = this.fbProvider.libraryStore[overlayEvent.data.logo];
        });
        this.detectChanges();
      }
    });
    await modal.present();
  }

  @computed get eventLogo(): Media {
    const image = this.fbProvider.libraryStore
      .filter(image => {
        if (image.id == this.event.quickAccessLogo) {
          return image;
        }
      })
      .reduce((_, image) => image, null);

    this.logo = image;

    return image;
  }

  @computed get eventMedia(): Media {
    const media = this.fbProvider.libraryStore
      .filter(media => {
        if (media.id == this.event.media) {
          return media;
        }
      })
      .reduce((_, media) => media, null);

    this.media = media;

    return media;
  }

  @computed get eventSponsorLogo(): Media {
    if (this.eventSponsor != undefined) {
      const logo = this.fbProvider.libraryStore
        .filter(logo => {
          if (logo.id == this.eventSponsor.logo) {
            return logo;
          }
        })
        .reduce((_, logo) => logo, null);

      this.sponsorLogo = logo;

      return logo;
    }
  }

  @computed get eventSponsor(): Sponsor {
    const sponsor = this.fbProvider.siteSponsors
      .filter(sponsor => {
        if (sponsor.id == this.event.sponsor) {
          return sponsor;
        }
      })
      .reduce((_, sponsor) => sponsor, null);

    this.sponsor = sponsor;

    return sponsor;
  }


  detectChanges() {
    this.changeDetectorRef.detectChanges();
  }

  dismiss(save: boolean) {
    if (save) {
      if (this.event.name == '(New Event)') {
        this.fbProvider.removeEvent(this.event);
      } else {
        this.fbProvider.updateEvent(this.event);
      }
    } else {
      if (this.event.name == '(New Event)') {
        this.fbProvider.removeEvent(this.event);
      }
    }
    this.modalCtrl.dismiss();
  }
}
