/* Ionic & Angular core imports */
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

/* Components */
import { GlobalComponentsModule } from '../global/global.components.module';
import { ScreenEditorComponent } from './screen-editor/screen-editor';

@NgModule({
  declarations: [
    ScreenEditorComponent,
  ],
  imports: [
    IonicModule,
    GlobalComponentsModule,
    CommonModule,
    FormsModule
  ],
  exports: [
    ScreenEditorComponent,
  ]
})
export class SettingsComponentsModule { }
