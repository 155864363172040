/* Ionic & Angular core imports */
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';

/* Types and interfaces */
import { User } from '../../../stores/user.store';
import { Site } from '../../../stores/sites.store';

/* Providers */
import { ApplicationProvider } from '../../../providers/application/application';
import { AuthProvider } from '../../../providers/auth/auth';

/* Pages*/
import { InitialPage } from '../../../pages/initial/initial';
import { SitesPage } from '../../../pages/sites/sites';
import { SiteEditorComponent } from '../../sites/site-editor/site-editor';
import { FirebaseProvider2 } from 'src/app/providers/firebase/firebase';
import { observable, toJS } from 'mobx';
import { Router } from '@angular/router';

@Component({
  selector: 'app-main-menu',
  templateUrl: 'main-menu.html',
  styleUrls: ['main-menu.scss']
})
export class MainMenuComponent {
  user: User;
  currentSite: Site;
  test = false;

  constructor(
    // private _events      : Events,
    private appProvider: ApplicationProvider,
    private authProvider: AuthProvider,
    // private _app         : IonApp,
    private navCtrl: NavController,
    private fbProvider: FirebaseProvider2,
    private changeDetector: ChangeDetectorRef,
    private modalCtrl: ModalController,
    private router: Router
  ) {
    this.listenToEvents();
  }

  async listenToEvents() {
    // TODO:
    await this.fbProvider.initFirebase();

    this.currentSite = this.fbProvider.currentSite;
    this.fbProvider.usersUpdated.subscribe(() => {
      this.fbProvider.getCurrentUser()
      .then(async user => {
        if (JSON.stringify(this.user) !== JSON.stringify(user)) {
          this.user = user;
          this.currentSite = this.fbProvider.currentSite;
          this.changeDetector.detectChanges();
        }
      })
        .catch(error => console.log('ERROR: ', error));
    });
  }

  logout() {
    this.appProvider.confirmDialog('Do you really want to log out?', async () => {
      await this.authProvider.logoutUser();
      await this.appProvider.showInformation('Success');
      this.navCtrl.navigateRoot('');
      this.user = null;
    });
  }

  async manageAccess() {
    // this._app.getRootNav().push(SiteEditorComponent, { site: this.currentSite }, { animation: 'ios-transition' });
    if (this.currentSite) {
      const modal = await this.modalCtrl.create({
        component: SiteEditorComponent,
        componentProps: { site: this.currentSite }
      });
      modal.onWillDismiss().then(async val => {
        await this.appProvider.dismissLoading();
      });
      await modal.present();
    }
  }

  isSiteOwner(): boolean {
    if (this.fbProvider.isSiteOwner(this.currentSite)) {
      return true;
    }
    return false;
  }

  changeSite() {
    localStorage.removeItem('siteId');
    this.currentSite = null;
    // this._app.getRootNav().setRoot(SitesPage, null, { animation: 'ios-transition' });
    this.navCtrl.navigateRoot('sites');
  }

  configureTeam() {
    if (this.currentSite) {
      // this.router.navigate(['/team'], { queryParams: { site: this.currentSite.id }})
      this.navCtrl.navigateRoot('team');
    }
  }

  configureScreens() {
    if (this.currentSite) {
      this.router.navigate(['/settings'], { queryParams: { site: this.currentSite.id } });
    }
    // this._app.getRootNav().push(SettingsPage, { site: this.currentSite }, { animation: 'ios-transition' });
  }

  controlScreen() {
    if (this.currentSite) {
      // TODO: Should be site-director in the future when available.
      this.navCtrl.navigateRoot('overview/library');
    }
  }
}
