import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { SponsorTiersPageRoutingModule } from './sponsor-tiers-routing.module';

import { SponsorTiersPage } from './sponsor-tiers.page';
import { GlobalComponentsModule } from 'src/app/components/global/global.components.module';
import { MobxAngularModule } from 'mobx-angular';
import { SponsorsComponentsModule } from 'src/app/components/sponsors/sponsors.components.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    MobxAngularModule,
    GlobalComponentsModule,
    SponsorTiersPageRoutingModule,
    SponsorsComponentsModule
  ],
  declarations: [SponsorTiersPage]
})
export class SponsorTiersPageModule {}
