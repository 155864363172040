import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ModalController, NavParams, PickerController } from '@ionic/angular';
import { action, computed, observable, runInAction } from 'mobx';
import { ApplicationProvider } from 'src/app/providers/application/application';
import { FirebaseProvider2 } from 'src/app/providers/firebase/firebase';
import { MediaPickerComponent } from '../../library/media-picker/media-picker';
import { OverlayEventDetail } from '@ionic/core';
import { Media, Sponsor } from 'src/app/stores/media.store';
import { Player, Position } from 'src/app/stores/player.store';
import { ApplicationConfig } from 'src/environments/environment';
import { SponsorPickerComponent } from '../../sponsors/sponsor-picker/sponsor-picker.component';

@Component({
  selector: 'app-player-editor',
  templateUrl: './player-editor.component.html',
  styleUrls: ['./player-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlayerEditorComponent implements OnInit {

  player: Player;

  externalServerAddress: string;

  @observable facePhoto: Media;
  @observable sponsor: Sponsor;
  @observable sponsorLogo: Media;
  @observable video: Media;

  constructor(
    public changeDetectorRef: ChangeDetectorRef,
    public fbProvider: FirebaseProvider2,
    private modalCtrl: ModalController,
    private appProvider: ApplicationProvider,
    private pickerController: PickerController,
    appConfig: ApplicationConfig,
    params: NavParams
  ) {
    fbProvider.initFirebase();

    this.player = Object.assign({}, params.get('player'));
    this.externalServerAddress = appConfig.externalLibrary.viewURL;
  }

  ngOnInit() {
    this.fbProvider.sponsorsUpdated.subscribe(
      () => this.detectChanges()
    );
  }

  dismiss(save: boolean) {
    if (save) {
      if (this.player.lastName == '(New Player)') {
        this.fbProvider.removePlayer(this.player);
      } else {
        this.fbProvider.updatePlayer(this.player);
      }
    } else {
      if (this.player.lastName == '(New Player)') {
        this.fbProvider.removePlayer(this.player);
      }
    }
    this.modalCtrl.dismiss();
  }

  dismissAndRemove() {
    this.appProvider.confirmDialog(`Are you sure you want to remove <b> ${this.player.lastName} from the team?`,
      async () => {
        await this.appProvider.presentLoadingDefault();

        this.fbProvider.removePlayer(this.player);
        this.modalCtrl.dismiss();

        await this.appProvider.dismissLoading();
    });
  }

  @action async presentPositionPicker() {
    const picker = await this.pickerController.create({
      buttons: [
        {
          text: 'Confirm',
          handler: (selected) => {
            this.player.position = selected.position.value;
          }
        }
      ],
      columns: [
        {
          name: 'position',
          options: [
            {
              text: 'Forward',
              value: Position.FORWARD
            },
            {
              text: 'Defense',
              value: Position.DEFENSE
            },
            {
              text: 'Goalkeeper',
              value: Position.GOALKEEPER
            },
            {
              text: 'Head coach',
              value: Position.MAIN_COACH
            },
            {
              text: 'Assistant coach',
              value: Position.ASS_COACH
            }
          ]
        }
      ]
    });
    picker.onWillDismiss().then(() => {
      this.detectChanges();
    });
    await picker.present();
  }

  @action async addPlayerPhotoFace() {
    const modal = await this.modalCtrl.create({
      component: MediaPickerComponent,
      componentProps: {
        mediaType: 'image'
      }
    });
    modal.onWillDismiss()
      .then((overlayEvent: OverlayEventDetail<Media>) => {
        if (overlayEvent.data) {
          runInAction(() => {
            this.player.photoFace = overlayEvent.data.id;
            this.facePhoto = overlayEvent.data;
          });
          this.detectChanges();
        }
      }
    );
    modal.present();
  }

  @action async addPlayerSponsor() {
    const modal = await this.modalCtrl.create({
      component: SponsorPickerComponent
    });

    modal.onWillDismiss().then((overlayEvent: OverlayEventDetail<Sponsor>) => {
      if (overlayEvent.data) {
        runInAction(() => {
          this.player.sponsor = overlayEvent.data.id;
          this.sponsorLogo = this.fbProvider.libraryStore[overlayEvent.data.logo];
        });
        this.detectChanges();
      }
    });
    await modal.present();
  }

  @action async addPlayerVideo() {
    const modal = await this.modalCtrl.create({
      component: MediaPickerComponent,
      componentProps: {
        mediaType: 'video'
      }
    });
    modal.onWillDismiss().then((overlayEvent: OverlayEventDetail<Media>) => {
      if (overlayEvent.data) {
        runInAction(() => {
          this.player.video = overlayEvent.data.id;
          this.video = overlayEvent.data;
        });
        this.detectChanges();
      }
    });
    await modal.present();
  }

  @computed get playerPhotoFace(): Media {
    const image = this.fbProvider.libraryStore
      .filter(image => {
        if (image.id == this.player.photoFace) {
          return image;
        }
      })
      .reduce((_, image) => image, null);

    this.facePhoto = image;

    return image;
  }

  @computed get playerSponsorLogo(): Media {
    if (this.playerSponsor != undefined) {
      const image = this.fbProvider.libraryStore
        .filter(image => {
          if (image.id == this.playerSponsor.logo) {
            return image;
          }
        })
        .reduce((_, image) => image, null);

      this.sponsorLogo = image;

      return image;
    }
  }

  @computed get playerVideo(): Media {
    const video = this.fbProvider.libraryStore
      .filter(video => {
        if (video.id == this.player.video) {
          return video;
        }
      })
      .reduce((_, video) => video, null);

    this.video = video;

    return video;
  }

  @computed get playerSponsor(): Sponsor {
    const sponsor = this.fbProvider.siteSponsors
      .filter(sponsor => {
        if (sponsor.id == this.player.sponsor) {
          return sponsor;
        }
      })
      .reduce((_, sponsor) => sponsor, null);

    this.sponsor = sponsor;

    return sponsor;
  }

  detectChanges() {
    this.changeDetectorRef.detectChanges();
  }
}
