/* Ionic & Angular core imports */
import { Component, ChangeDetectionStrategy, OnInit, OnDestroy, ChangeDetectorRef, ViewChild } from '@angular/core';
import { ModalController, NavParams, NavController, IonList } from '@ionic/angular';


/* Types and interfaces */
import { Presentation, Media } from '../../stores/media.store';
import { Site } from '../../stores/sites.store';

/* Providers */
import { ApplicationProvider } from '../../providers/application/application';
import { FirebaseProvider2 } from 'src/app/providers/firebase/firebase';

/* Components */
import { CollectionEditorComponent } from 'src/app/components/collections/collection-editor/collection-editor';
import { CollectionPlayerPage } from '../collection-player/collection-player';
import { action, computed } from 'mobx-angular';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthProvider } from 'src/app/providers/auth/auth';
import { observable } from 'mobx';

@Component({
  selector: 'app-page-collections',
  templateUrl: 'collections.html',
  styleUrls: ['collections.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CollectionsPage implements OnInit, OnDestroy {

  // reference to ion-list so we can close
  // all opened slider menus it from here
  @ViewChild(IonList) list: IonList;

  constructor(
    private appProvider: ApplicationProvider,
    private modalCtrl: ModalController,
    public fbProvider: FirebaseProvider2,
    private navCtrl: NavController,
    public changeDetectorRef: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    authProvider: AuthProvider,
    private router: Router
  ) {
    // this.activatedRoute.params.subscribe(async params => {
    //   console.log(this.fbProvider.listOfCollections);
    //   console.log(this.fbProvider.availableUsers);
    //   console.log(this.fbProvider.availableSites);
    //   // this.site = new Site(this.fbProvider.currentSite, this.fbProvider);
    //   console.log("this site: ", this.site);
    //   console.log("current site:", this.fbProvider.currentSite)
    // });
  }

  @action async editSlide(collection: Presentation, collectionIndex: number) {
    this.list.closeSlidingItems();
    const index = this.fbProvider.listOfCollections.findIndex(s => s.id === collection.id);
    const modal = await this.modalCtrl.create({
      component: CollectionEditorComponent,
      componentProps: {
        collectionIndex: index,
        collection: Object.assign({}, collection),
        collectionLibrary: this.getLibraryForSlideOnly(collection),
        library: this.fbProvider.libraryStore
      }
    });

    modal.onWillDismiss().then(async () => {
      await this.appProvider.presentLoadingDefault();
      await this.appProvider.showInformation(`Success`);
      await this.appProvider.dismissLoading();
      this.detectChanges();
    });
    await modal.present();
  }

  async setup() {
    await this.fbProvider.initFirebase(this.changeDetectorRef);
    // await this.fbProvider.getCurrentUser();
    // this.dismissLoading();
  }

  // ngOnInit is required as if this is not implemented,
  // then it is not called and we create dynamically ngOnInit
  // function in AutoAsync decorator
  async ngOnInit() {
    // await this.appProvider.presentLoadingDefault();
    this.fbProvider.collectionsUpdated.subscribe(
      async () => {
        this.detectChanges();
        console.log("collections updated")
        await this.dismissLoading();
      }
    );
    await this.setup();
   }
  ngOnDestroy() { }

  /**
   * Triggers change detection. We have to do it manually as we have set up
   * Change Detection Strategy to OnPush.
   */
  detectChanges() {
    this.changeDetectorRef.detectChanges();
  }

  async dbUpdated() {
    await this.appProvider.showInformation('Success');
    await this.appProvider.dismissLoading();
    this.detectChanges();
  }

  async dbUpdateError() {
    await this.appProvider.showInformation('Cannot create new collection!');
    await this.appProvider.dismissLoading();
    this.detectChanges();
  }

  /**
   * Function that is called when data is being loaded
   * for the first time
   */
  async dismissLoading() {
    // dismiss loading message and overlay
    await this.appProvider.dismissLoading();
  }

  removeSlide(collection: Presentation) {
    this.list.closeSlidingItems();

    // confirm action
    this.appProvider.confirmDialog(`Do you really want to remove presentation <b>${collection.name}</b>?`, () => {
      const index = this.fbProvider.listOfCollections.findIndex(s => s.id === collection.id);
      // this.fbProvider.listOfCollections.splice(index, 1);
      this.fbProvider.removeCollection(collection);
      this.detectChanges();
    });
  }



  getLibraryForSlideOnly(collection: Presentation) {
    const result: Media[] = [];
    let tmpMedia: Media;

    if (this.fbProvider.libraryStore && collection.mediaIds) {
      collection.mediaIds.forEach(mediaId => {
        tmpMedia = this.fbProvider.libraryStore.find(m => m.id === mediaId);
        if (tmpMedia) {
          result.push(tmpMedia);
        }
      });
    }

    return result;
  }

  async selectCollection(slide: Presentation) {
    // this.appProvider.presentLoadingDefault();
    this.router.navigate(['/overview/collections/player'], { queryParams: { slideId : slide.id } });
  }

  addCollection() {
    this.appProvider.confirmDialog(`Do you really want to create an empty collection?`, async () => {
      await this.appProvider.presentLoadingDefault();
      const presentation: Presentation = new Presentation(null, localStorage.getItem('siteId'));

      this.fbProvider.updateCollection(presentation).then(
        async () => {
          await this.appProvider.dismissLoading();
        }
      );
    });
  }
}
