/* Ionic & Angular core imports */
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { GlobalComponentsModule } from '../global/global.components.module';

/* Components */
import { SiteEditorComponent } from './site-editor/site-editor';

@NgModule({
	declarations: [
    SiteEditorComponent
  ],
  imports: [
    IonicModule,
    FormsModule,
    GlobalComponentsModule,
    CommonModule
  ],
  exports: [
    SiteEditorComponent,
    GlobalComponentsModule
  ],
  entryComponents: [
    SiteEditorComponent
  ]
})
export class SitesComponentsModule {}
