import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { IonList, ModalController } from '@ionic/angular';
import { observable } from 'mobx';
import { SponsorEditorComponent } from 'src/app/components/sponsors/sponsor-editor/sponsor-editor.component';
import { ApplicationProvider } from 'src/app/providers/application/application';
import { FirebaseProvider2 } from 'src/app/providers/firebase/firebase';
import { Sponsor } from 'src/app/stores/media.store';
import { Site } from 'src/app/stores/sites.store';

@Component({
  selector: 'app-sponsors',
  templateUrl: './sponsors.page.html',
  styleUrls: ['./sponsors.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SponsorsPage implements OnInit {

  @ViewChild(IonList) list: IonList;

  constructor(
    public changeDetectorRef: ChangeDetectorRef,
    public fbProvider: FirebaseProvider2,
    private applicationProvider: ApplicationProvider,
    private modalCtrl: ModalController
  ) {
  }

  async ngOnInit() {
    await this.setup();
    this.fbProvider.sponsorsUpdated.subscribe(() => this.detectChanges());
  }

  async setup() {
    await this.fbProvider.initFirebase(this.changeDetectorRef);
    await this.fbProvider.getCurrentUser();
    // console.log("Sponsor site:", this.site.id);
    // this.dismissLoading();
    // setup uploader
  }

  async addNewSponsor() {
    if (this.fbProvider.currentSite != null) {
      this.fbProvider.updateSponsor(new Sponsor(null,  this.fbProvider.currentSite.id))
        .then(async () => {
          const modal = await this.modalCtrl.create({
            component: SponsorEditorComponent,
            componentProps: {
              sponsor: this.fbProvider.siteSponsors[this.fbProvider.siteSponsors.length - 1]
            }
          });
          modal.present();

          modal.onWillDismiss().then(() => this.detectChanges());
        }
      );
    }
  }

  async removeSponsor(sponsor: Sponsor) {
    await this.list.closeSlidingItems();
    const currentSponsor = this.fbProvider.siteSponsors.filter(currentSponsor => {
      if (currentSponsor.id === sponsor.id) {
        return currentSponsor;
      }
    })
    .reduce((_, sponsor) => sponsor, null);

    this.applicationProvider.confirmDialog(`Do you really want to remove sponsor <b>${currentSponsor.name}</b>?`, () => {
      this.fbProvider.removeSponsor(sponsor);

      this.detectChanges();
    });
  }

  async editSponsor(sponsor: Sponsor) {
    await this.list.closeSlidingItems();

    const modal = await this.modalCtrl.create({
      component: SponsorEditorComponent,
      componentProps: { sponsor: Object.assign({}, sponsor)}
    });

    modal.onWillDismiss().then(() => {
      this.detectChanges();
    });

    modal.present();
  }

  getSponsorLogo(sponsor: Sponsor) {
    const image = this.fbProvider.libraryStore.filter(image => {
      if (image.isImage && image.id == sponsor.logo) {
        return image;
      }
    }).reduce((_, image) => image, null);

    return image ? image.thumbnail : '';
  }

  detectChanges() {
    this.changeDetectorRef.detectChanges();
  }

}
