/* Ionic & Angular core imports */
import { Component, ViewChild } from '@angular/core';
import { NavParams, ModalController, PopoverController, IonList } from '@ionic/angular';

/* Types & interfaces */
import { Presentation, Media } from '../../../stores/media.store';

/* Providers */
import { ApplicationProvider } from '../../../providers/application/application';
import { FirebaseProvider2 } from 'src/app/providers/firebase/firebase';

import { MediaAdderPopoverComponent } from '../media-adder/media-adder';
import { ItemReorderEventDetail, OverlayEventDetail } from '@ionic/core';

@Component({
  selector: 'app-collection-editor',
  templateUrl: 'collection-editor.html',
  styleUrls: ['collection-editor.scss']
})
export class CollectionEditorComponent {

  @ViewChild(IonList) list: IonList;

  collectionIndex: number;
  collection: Presentation;
  collectionLibrary: Media[];
  library: Media[];

  // reference to ion-list so we can close
  // all opened slider menus it from here

  constructor(
    private appProvider: ApplicationProvider,
    public fbProvider: FirebaseProvider2,
    private viewCtrl: ModalController,
    // private popoverCtrl: PopoverController,
    private modalCtrl: ModalController,
    params: NavParams,
  ) {
    this.collectionIndex = params.get('collectionIndex');
    this.collection = params.get('collection');
    this.collectionLibrary = params.get('collectionLibrary');
    this.library = params.get('library');
  }

  /**
   * Function will close modal
   */
  dismiss(save: boolean) {
    if (save) {
      this.fbProvider.updateCollection(this.collection);
      if (this.collection.mediaIds) {
        this.fbProvider.updatePresentationMediaIds(this.collection, this.collection.mediaIds)
          .then(async () => {
            await this.appProvider.dismissLoading();
          }
        );
      }
    }
    this.viewCtrl.dismiss();
  }

  reorderData(event: CustomEvent<ItemReorderEventDetail> | any) {
    const initialValue: Media[] = [];
    this.collection.mediaIds = (event.detail.complete(this.collectionLibrary) as Media[])
      .map(media => media.id);
  }

  removeMediaFromSlide(media: Media) {
    this.list.closeSlidingItems();

    // confirm action
    this.appProvider.confirmDialog(`Do you really want to remove <b>${media.name}</b> from this presentation?`, () => {
      const index = this.collection.mediaIds.findIndex(id => id === media.id);
      this.collection.mediaIds.splice(index, 1);
      this.collectionLibrary.splice(index, 1);
    });
  }

  async addToCollection(mediaId: string, duplicationCheck = true) {

    if (!!this.collectionLibrary.find(m => m.id === mediaId) && duplicationCheck) {
      await this.appProvider.showInformation('This media is already on the list');
      await this.appProvider.dismissLoading();
      return;
    }

    this.collectionLibrary.push(this.library.find(m => m.id === mediaId));

    if (this.collection.mediaIds) {
      this.collection.mediaIds = [...this.collection.mediaIds, mediaId];
      this.fbProvider.updatePresentationMediaIds(this.collection, this.collection.mediaIds)
        .then(async () => {
          await this.appProvider.dismissLoading();
        });
    } else {
      this.collection.mediaIds = [mediaId];
      this.fbProvider.updatePresentationMediaIds(this.collection, this.collection.mediaIds)
        .then(async () => {
          await this.appProvider.dismissLoading();
        });
    }
  }

  async mediaAdder() {
    const popover = await this.modalCtrl.create({
      component: MediaAdderPopoverComponent,
      componentProps: {
        library: this.library,
        currentMedia: this.collectionLibrary
      },
    });

    popover.onWillDismiss().then(async (event: OverlayEventDetail<{ addedMedia: Media[] }>) => {
      if (event.data) {

        await this.appProvider.presentLoadingDefault();
        event.data.addedMedia.forEach(media => {
          this.addToCollection(media.id, false);
        });
        await this.appProvider.dismissLoading();
      }
    });

    return await popover.present();
  }
}
