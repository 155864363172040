/* Angular & Ionic core imports */
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

/* Components */
import { HeaderComponent } from './header/header';
import { MainMenuComponent } from './main-menu/main-menu';

@NgModule({
	declarations: [
		HeaderComponent,
		MainMenuComponent,
	],
	imports: [
    IonicModule,
    CommonModule,
    FormsModule
	],
	exports: [
		HeaderComponent,
		MainMenuComponent
	]
})
export class GlobalComponentsModule { }
