import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CollectionPlayerPage } from '../collection-player/collection-player';
import { CollectionsPage } from './collections';


const routes: Routes = [
  {
    path: '',
    component: CollectionsPage,
  },
  {
    path: 'player',
    loadChildren: () => import('../collection-player/collection-player.module').then( m => m.CollectionPlayerPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class CollectionsPageRoutingModule { }
