/* Other */
import { UUID } from 'angular2-uuid';
import * as moment from 'moment';

export class Media {
  id = UUID.UUID();
  siteId = '';
  name = '(new media)';
  isOnExternalServer = false;
  source = '';
  thumbnail = '';
  md5 = '';
  size = 0;
  resolution = '';
  mime = '';
  uploadedDate: string = moment(new Date()).format('YYYY-MM-DD, HH:mm');
  isImage = true;

  constructor(element: any = null, siteId: string) {
    this.siteId = siteId;
    if (element) {
      this.isImage = element.mime.substr(0, 5) === 'image';

      for (const key in element) {
        if (this.hasOwnProperty(key)) {
          this[key] = element[key];
        }
      }
    }
  }
}

export class Presentation {
  id = UUID.UUID();
  siteId = '';
  name = '(new collection)';
  created = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
  mediaIds: string[] = [];

  constructor(element: any = null, siteId: string) {
    this.siteId = siteId;
    if (element) {
      element.forEach(key => {
        if (this.hasOwnProperty(key)) {
          this[key] = element[key];
        }
      });
    }
  }
}

export class SponsorTier {
  id = UUID.UUID();
  siteId = '';
  created = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');

  name: string = '(New Sponsor tier)';
  logo: string; // id of the logo in the library.

  constructor(element: any = null, siteId: string) {
    this.siteId = siteId;
    if (element) {
      for (const key in element) {
        if (this.hasOwnProperty(key)) {
          this[key] = element[key];
        }
      }
    }
  }
}

export class Sponsor {
  id = UUID.UUID();
  siteId = '';
  created = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');

  // Sponsor specific
  name = '(New Sponsor)';
  logo: string = ''; // id of the logo in the library.
  video: string = ''; // (Optional) id of the sponsor's video in the library.
  slogan: string;
  sponsorTier: string; // id of the sponsor tier.

  constructor(element: any = null, siteId: string) {
    this.siteId = siteId;
    if (element) {
      for (const key in element) {
        if (this.hasOwnProperty(key)) {
          this[key] = element[key];
        }
      }
    }
  }
}

export class AutoplayConfig {
  mediaIds: string[] = [];
  secondsBetween = 10;
  repeat = false;
  randomize = false;
  // currentIndex  : number   = 0;

  constructor(element: any = null) {
    if (element) {
      for (const key in element) {
        if (this.hasOwnProperty(key)) {
          this[key] = element[key];
        }
      }
    }
  }
}



