import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { PlayersPageRoutingModule } from './players-routing.module';

import { PlayersPage } from './players.page';
import { GlobalComponentsModule } from 'src/app/components/global/global.components.module';
import { MobxAngularModule } from 'mobx-angular';
import { PlayersComponentsModule } from 'src/app/components/players/players.components.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    PlayersPageRoutingModule,
    PlayersComponentsModule,
    GlobalComponentsModule,
    MobxAngularModule
  ],
  declarations: [PlayersPage]
})
export class PlayersPageModule {}
