/* Ionic & Angular core imports */
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';

/* Pages */
import { SitesPage } from './sites';

/* Components */
import { SitesComponentsModule } from '../../components/sites/sites.components.module';
import { MobxAngularModule } from 'mobx-angular';
import { CommonModule } from '@angular/common';
import { GlobalComponentsModule } from 'src/app/components/global/global.components.module';

@NgModule({
  declarations: [
    SitesPage,
  ],
  imports: [
    IonicModule,
    SitesComponentsModule,
    MobxAngularModule,
    CommonModule,
    GlobalComponentsModule
  ],
  providers: [
  ]
})
export class SitesPageModule { }
