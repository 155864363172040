// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false
};

export const firebaseConfig = {
  apiKey           : 'AIzaSyBQRBMQ5Qc-xZX4R6nIYMB0LYS8rpL30wk',
  authDomain       : 'soft-ice-projector.firebaseapp.com',
  databaseURL      : 'https://soft-ice-projector.firebaseio.com',
  projectId        : 'soft-ice-projector',
  storageBucket    : 'soft-ice-projector.appspot.com',
  messagingSenderId: '548941148774'
};

export class ApplicationConfig {
  /** Settings related to common application parts */
  public applicationConfig = {
      infoMessageDurationSec: 1,
      infoMessagePosition   : 'bottom',
      deviceIdKey           : 'deviceId'
  };

  public externalLibrary = {
      uploadURL: 'https://jumbotron.softice.com/upload/',
      // uploadURL: 'http://test.local/',
      viewURL  : 'https://jumbotron.softice.com/'
  };

  public mode = 'development';
}


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
