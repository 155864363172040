import { observable, action, computed } from 'mobx-angular';
import { extendObservable, observe, runInAction, toJS } from 'mobx';

/* Firebase */
import * as firebase from 'firebase/app';
import 'firebase/database';

/* Stores */
import { Site } from '../../stores/sites.store';
import { User } from '../../stores/user.store';
import { Presentation, Media, Sponsor, SponsorTier } from '../../stores/media.store';
import { Screen } from '../../stores/screen.store';
import { ChangeDetectorRef, EventEmitter, Injectable } from '@angular/core';
import { firebaseConfig } from 'src/environments/environment';
import { AuthProvider } from '../auth/auth';
import { Subject } from 'rxjs';
import { Player } from 'src/app/stores/player.store';
import { Event } from 'src/app/stores/event.store';
import { ApplicationProvider } from '../application/application';
import { Mutex } from 'async-mutex';
import { Team } from 'src/app/stores/team.store';

@Injectable({
  providedIn: 'root'
})
export class FirebaseProvider2 {

  private loggedInMutex: Mutex;

  // Database root node
  @observable dbRootNode: string;

  @observable private users: { [key: string]: User } = observable.object({});

  @observable private sites: { [key: string]: Site } = observable.object({});

  @observable private screens: { [key: string]: Screen } = observable.object({});

  @observable private collections: { [key: string]: Presentation } = observable.object({});

  @observable private library: { [key: string]: Media } = observable.object({});

  @observable private sponsors: { [key: string]: Sponsor } = observable.object({});

  @observable private sponsorTiers: { [key: string]: SponsorTier } = observable.object({});

  @observable private players: { [key: string]: Player } = observable.object({});

  @observable private teams: { [key: string]: Team } = observable.object({});

  @observable private events: { [key: string]: Event } = observable.object({});

  @observable private user: User;

  private observersInitialized = false;

  usersUpdated: EventEmitter<null> = new EventEmitter();

  sitesUpdated: EventEmitter<null> = new EventEmitter();

  screensUpdated: EventEmitter<null> = new EventEmitter();

  collectionsUpdated: EventEmitter<null> = new EventEmitter();

  libraryUpdated: EventEmitter<null> = new EventEmitter();

  sponsorsUpdated: EventEmitter<null> = new EventEmitter();

  sponsorTiersUpdated: EventEmitter<null> = new EventEmitter();

  playersUpdated: EventEmitter<null> = new EventEmitter();

  eventsUpdated: EventEmitter<null> = new EventEmitter();

  teamsUpdated: EventEmitter<null> = new EventEmitter();

  teamsLoaded: EventEmitter<null> = new EventEmitter();

  /** Array of database path value listeners */
  public dbNodeListeners: firebase.database.Reference[] = [];

  public loginSubject = new Subject<boolean>();

  /** App variable - DBnode map */
  private dbNodes = [
    'library', 'collections', 'screens',
    'sponsors', 'sponsorTiers',
    'players', 'events', 'teams'
  ];

  private dbRootNodeRef: firebase.database.Reference;


  constructor(
    private authProvider: AuthProvider,
    private applicationProvider: ApplicationProvider) {
      this.loggedInMutex = new Mutex();
  }


  /**
   * Function to set DB root node ref after the
   * root node has been assigned
   */
  @action setDBRootNodeRef() {
    this.dbRootNodeRef = firebase.database().ref(this.dbRootNode);
  }

  private mediaCommonality(oldMedia: any, newMedia: any): boolean {
    if (!newMedia || !oldMedia) {
      // When removing media
      return false;
    }
    if (newMedia.siteId === localStorage.getItem('siteId')) {
      for (const key in newMedia) {
        if (JSON.stringify(oldMedia[key]) !== JSON.stringify(newMedia[key])) {
          return false;
        }
      }
      return true;
    }
    return false;
  }

  private addStoreObservers() {
    if (!this.observersInitialized) {
      this.dbNodes.forEach(nodePath => {
        observe(this[nodePath], (val) => {
          if (!this.mediaCommonality(val.oldValue, val.newValue)) {
            // console.log(val)
            this[nodePath + 'Updated'].emit(null);
          }
        });
      });
      this.observersInitialized = true;
    }
  }

  @action public addDBNodeListeners(godmode: boolean = false) {
    this.addStoreObservers();

    this.dbNodes.forEach(nodePath => {
      // TODO: Should be removed eventually, when the database i changed as well.
      let remoteName = nodePath;
      if (nodePath === 'collections') {
        remoteName = 'presentations';
      }

      if (localStorage.getItem('siteId') != null) {
        if (godmode) {
          // console.trace()
          this.dbNodeListeners[remoteName] = this.dbRootNodeRef.child(remoteName)
            .on('value', action(snapshot => this.snapshot(snapshot, nodePath)));
        } else {
          this.dbNodeListeners[remoteName] = this.dbRootNodeRef.child(remoteName)
            .orderByChild('siteId')
            .equalTo(localStorage.getItem('siteId'))
            .on('value', action(snapshot => this.snapshot(snapshot, nodePath)))
        }
      }
    });
  }

  @action public addDBUserListener(godMode: boolean = false) {
    const userName = 'users';
    observe(this[userName], (val) => {
      this['usersUpdated'].emit(null);
    });
    this.dbNodeListeners[userName] = this.dbRootNodeRef.child(userName)
      .on('value', action(snapshot => this.snapshot(snapshot, userName)));
  }

  @action public addDBSiteListener(godMode: boolean = false) {
    const siteName = 'sites';
    observe(this[siteName], (val) => {
      this['sitesUpdated'].emit(null);
    });
    this.dbNodeListeners[siteName] = this.dbRootNodeRef.child(siteName)
      .on('value', action(snapshot => this.snapshot(snapshot, siteName)));
  }

  difference(obj1, obj2) {
    let keyFound: any = false;
    Object.keys(obj1).forEach(key => {
       if(obj1[key] !== obj2[key]){
          return keyFound = {[key]: obj2[key]};
       }
    });
    return keyFound || -1;
 };

  @action async snapshot(snapshot: firebase.database.DataSnapshot, nodePath: string) {
    if (JSON.stringify(toJS(this[nodePath])) !== JSON.stringify(await snapshot.val())) {
      this[nodePath] = Object.assign(this[nodePath], snapshot.val());
      // if (nodePath === 'library') {
      //   console.log(this.difference(toJS(this[nodePath]), snapshot.val()))
      // }
      // extendObservable(this[nodePath], snapshot.val());
      // this[nodePath] = observable.set(snapshot.val())
    }
  }

  get userId(): string {
    return firebase.auth().currentUser.uid;
  }

  @action public async initFirebase(ref: ChangeDetectorRef = null) {
    // initiate firebase
    if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig);
      // console.log('Firebase: Initialized', firebase);
      this.dbRootNode = '/';
      this.setDBRootNodeRef();
      this.addDBNodeListeners();
      this.addDBUserListener();
      this.addDBSiteListener();
      if (ref !== null) {
        ref.detectChanges();
      }
      firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
    } else {
      this.setDBRootNodeRef();
      this.addDBNodeListeners();
      this.addDBUserListener();
      this.addDBSiteListener();
    }

    this.loggedInMutex.acquire()
      .then((release) => {
        firebase.auth().onAuthStateChanged(async user => {
          if (user) {
            console.log('User is logged in');
            // console.log(localStorage.getItem('siteId'));
            // console.log(firebase.auth().currentUser);
            await this.getCurrentUser();
            this.usersUpdated.emit();
            this.loginSubject.next(true);
            if (ref !== null) {
              ref.detectChanges();
            }
            if (this.currentUser != undefined && this.currentUser.developer) {
              // this.addDBNodeListeners(false);
              this.addDBNodeListeners(this.currentUser.developer);
            } else {
              this.addDBNodeListeners(false);
            }

          } else {
            console.log('User is NOT logged in');
            await this.authProvider.logoutUser();
            release();
          }
        });
      });
    // console.log('ls uid: ', this.authProvider.uid);
    // if (this.authProvider.uid) {
    //   await firebase.auth().signInWithCustomToken(this.authProvider.uid).then(async success => {
    //     await this.getCurrentUser();
    //     console.log("USER", this.currentUser);
    //   }).catch(error => {
    //     console.log(error.message);
    //   });
    // }

    // if (!localStorage.getItem('uid') || !firebase.auth().currentUser) {
    //   await this.authProvider.logoutUser();
    // }

  }


  @action async createUserEntry(user: firebase.User, name: string): Promise<string> {
    const userNodeRef = this.dbRootNodeRef.child('users');
    return userNodeRef.push().then(pushRef => {

      const userKey: string = pushRef.key;
      this.users[userKey] = {
        id: user.uid,
        email: user.email,
        name,
        developer: false,
        godmode: false
      };
      // Local update
      Object.assign(this.users[userKey], user);

      const updateObject = {};
      updateObject[userKey] = this.users[userKey];

      // Database update
      userNodeRef.update(updateObject);
      return userKey;
    });
  }

  @computed getUserById(id: string): User {
    const result: User = null;
    const initialValue: any = {};
    return this.availableUsers
      .filter(user => {
        if (user.id === id) {
          return user;
        }})
      .reduce((_, user) => user, initialValue);
  }

  @observable isSiteOwner(site: Site): boolean {
    if (this.userId === site.ownerId ||
        this.userId === 'Z6MFUoSdAIh0MbXos6z8UmtqGfE3' ||
        this.userId === 'SmbvzHD9HoRbP2tk0iqVgHb7t3t2' ||
        this.userId === 'tkrXPO4HCkMCHrbvNskskCW4vut2') {
      return true;
    }
    return false;
  }

  /**
   *
   * Updaters & Setters
   *
   */

    @action async updateSite(site: Site): Promise<string> {
    const siteNodeRef = this.dbRootNodeRef.child('sites');
    // const updateObject = {};
    const siteToUpdate = new Site(site, this);

    // do not update owner in database as this is only temporary helper in the class
    siteToUpdate.owner = null;
    if (this.sites[site.id]) {

      const updateObject = {};
      updateObject[site.id] = this.sites[site.id];

      updateObject[site.id].owner = null;
      return siteNodeRef.update(updateObject);
    } else {
      return siteNodeRef.push().then(pushRef => {

        const siteKey: string = pushRef.key;
        this.sites[siteKey] = siteToUpdate;
        // Local update
        Object.assign(this.sites[siteKey], siteToUpdate);

        const updateObject = {};
        updateObject[siteKey] = this.sites[siteKey];

        // Database update
        siteNodeRef.update(updateObject);
        return siteKey;
      });
    }
  }

  @action removeSite(site: Site): Promise<string> {
    const siteNodeRef = this.dbRootNodeRef.child('sites');
    const updateObject = {};

    if (this.sites[site.id]) {
      delete this.sites[site.id];
      updateObject[`${site.id}`] = null;
      return siteNodeRef.update(updateObject);
    }
  }


  @action async updateScreen(screen: Screen, idx: number): Promise<any> {
    const screenNodeRef = this.dbRootNodeRef.child('screens');
    const updateObject = {};
    // console.log("ADD SCREEN")
    runInAction(() => {
      if (this.screens[localStorage.getItem('siteId')][idx]) {
        this.screens[localStorage.getItem('siteId')][idx] = screen;
        updateObject[`${localStorage.getItem('siteId')}/${idx}`] = screen;
        return screenNodeRef.update(updateObject);
        // .then(updateRef => {
        //   return;
        // });
      } else {
        return screenNodeRef.push().then(action(pushRef => {
          const screenKey: string = pushRef.key;

          this.screens[localStorage.getItem('siteId')][idx] = screen;

          // Local update
          Object.assign(this.screens[localStorage.getItem('siteId')][idx], screen);

          updateObject[`${localStorage.getItem('siteId')}/${idx}`] = screen;
          screenNodeRef.update(updateObject);
          return `${localStorage.getItem('siteId')}/${idx}`;
        }));
      }
    });
  }

  @action async updateCollection(collection: Presentation): Promise<any> {
    // TODO: Remember to change to "collections" with db update
    const collectionNodeRef = this.dbRootNodeRef.child('presentations');
    const updateObject = {};

    const filteredCollection = Object.entries(this.collections)
      .filter(currentCollection => {
        if (currentCollection[1].id === collection.id) {
          return currentCollection;
        }
      })
      .reduce((obj, item) => obj, []);

    // Check if it exists
    if (this.collections[filteredCollection[0]]) {
      // Check if it belongs to current site
      if (this.collections[filteredCollection[0]].siteId === localStorage.getItem('siteId')) {
        this.collections[filteredCollection[0]] = Object.assign({}, collection);

        updateObject[filteredCollection[0]] = collection;

        return collectionNodeRef.update(updateObject);
      }
      return null;
    } else {
      return collectionNodeRef.push().then(action(pushRef => {
        const collectionKey: string = pushRef.key;

        this.collections[collection.id] = collection;

        // Local update
        Object.assign(this.collections[collection.id], collection);

        // Database update
        updateObject[collection.id] = this.collections[collection.id];
        collectionNodeRef.update(updateObject);
        return collection.id;
      }));
    }
  }

  @action async updateLibraryStore(mediaInput: Media): Promise<any> {
    const libraryNodeRef = this.dbRootNodeRef.child('library');
    const updateObject = {};
    const initialValue: any = {};

    const filteredMedia = Object.entries(this.library)
      .filter(media => {
        if (media[1] !== undefined) {
          if (media[1].id === mediaInput.id) {
            return media;
          }
        }
      })
      .reduce((obj, item) => obj, initialValue);

    if (this.library[filteredMedia[0]]) {
      this.library[filteredMedia[1]] = mediaInput;

      updateObject[filteredMedia[1]] = mediaInput;

      return libraryNodeRef.update(updateObject);
    } else {
      return libraryNodeRef.push().then(action(pushRef => {
        const libraryKey: string = pushRef.key;

        this.library[mediaInput.id] = mediaInput;

        // Local update
        Object.assign(this.library[mediaInput.id], mediaInput);

        // Database update
        updateObject[mediaInput.id] = this.library[mediaInput.id];
        libraryNodeRef.update(updateObject);
        return mediaInput.id;
      }));
    }
  }

  @action async updatePresentationMediaIds(collectionInput: Presentation, newMediaIdsArray: string[]): Promise<any> {
    const presentationNodeRef = this.dbRootNodeRef.child('presentations');
    const updateObject = {};

    const filteredCollection = Object.entries(this.collections)
      .filter(currentCollection => {
        if (currentCollection[1].id === collectionInput.id) {
          return currentCollection;
        }
      }).reduce((obj, item) => item);

    if (filteredCollection) {
      if (this.collections[filteredCollection[0]].mediaIds) {

        this.collections[filteredCollection[0]].mediaIds = Object.assign({}, ...newMediaIdsArray);
        // this.collections[filteredCollection[0]].mediaIds = newMediaIdsArray;
        updateObject[`${filteredCollection[0]}/mediaIds`] = newMediaIdsArray;
        return presentationNodeRef.update(updateObject);
      } else {
        return presentationNodeRef.push().then(action(pushRef => {
          const presentationKey: string = pushRef.key;

          this.collections[filteredCollection[0]].mediaIds = newMediaIdsArray;

          // Local update
          Object.assign(this.collections[filteredCollection[0]].mediaIds, newMediaIdsArray);

          updateObject[`${filteredCollection[0]}/mediaIds`] = newMediaIdsArray;
          presentationNodeRef.update(updateObject);
          return `${filteredCollection[0]}/mediaIds`;
        }));
      }
    }
  }

  @action removeCollection(collection: Presentation) {
    // TODO: Remember to change to "collections" with db update
    const collectionNodeRef = this.dbRootNodeRef.child('presentations');
    const updateObject = {};

    if (this.collections[collection.id]) {
      delete this.collections[collection.id];
      updateObject[collection.id] = null;
      return collectionNodeRef.update(updateObject);
    }
  }

  @action removeMedia(idx: number, media?: Media) {
    const libraryNodeRef = this.dbRootNodeRef.child('library');
    const updateObject = {};

    if (media !== null) {
      if (this.library[media.id]) {
        delete this.library[media.id];
        updateObject[media.id] = null;
        return libraryNodeRef.update(updateObject);
      }
    } else {
      if (this.library[idx]) {
        delete this.library[idx];
        updateObject[idx] = null;
        return libraryNodeRef.update(updateObject);
      }
    }
  }

  @action removeScreen(screenInput: Screen) {
    const screenNodeRef = this.dbRootNodeRef.child('screens');
    const updateObject = {};

    // console.log("SCREEN", screenInput)

    const screenToBeRemoved = Object.entries(this.screens[localStorage.getItem('siteId')])
      .filter(screen => {
        if (screen[1] !== undefined) {
          if (screen[1].id === screenInput.id) {
            return true;
          }
        }
        return false;
      })
      .reduce((obj, item) => obj);

    const idx = screenToBeRemoved[0];

    if (this.screens[localStorage.getItem('siteId')][idx]) {
      delete this.screens[localStorage.getItem('siteId')][idx];
      updateObject[`${localStorage.getItem('siteId')}/${idx}`] = null;
      return screenNodeRef.update(updateObject);
    }
  }


  @computed getCurrentCollection(slideId: string): Presentation {
    const initialPresentation = new Presentation(null, localStorage.getItem('siteId'));
    return this.listOfCollections
      .filter(collection => collection.id === slideId)
      .reduce((_, collection) => collection, initialPresentation);
  }

  // @observable teams: { [key: string]: Team } = {};

  /**
   *
   * Getters
   *
   */


  @computed get currentUser(): any {
    if (!firebase.auth().currentUser) {
      return null;
    } else if (Object.entries(this.users).length === 0) {
      return null;
    } else if (this.user != null) {
      // console.log("OPTIMIZED")
      return this.user;
    }
    // console.log("UNOPTIMIZED")

    const currentUser = Object.values(this.users)
      .filter(user => {
        if (user.id === this.userId) {
          return user;
        }
      })
      .reduce((_, user) => user, null);
    this.user = currentUser;
    return currentUser;
  }

  @action updateCurrentUser(user: User) {
    const userNodeRef = this.dbRootNodeRef.child('users');

    const userRef = userNodeRef.child(this.userId);

    return userRef.set(user);
  }

  @computed get currentSite(): Site {
    if (!localStorage.getItem('siteId')) {
      return null;
    }
    const currentSite = Object.values(this.sites)
      .filter(site => {
        if (site.id === localStorage.getItem('siteId')) {
          return site;
        }
      })
      .reduce((_, site) => site, null);
    return currentSite;
  }


  /**
   * Used to log in automatically when starting the application.
   */
  @action async getCurrentUser(): Promise<User> {
    if (!firebase.auth().currentUser) {
      return null;
    }
    let result;
    try {
      await firebase
        .database()
        .ref(`/users/${this.userId}`)
        .once('value', snapshot => {
          result = snapshot.val();
          this.user = result;
        });
      return result;
    } catch (e) {
      return null;
    }
  }

  @computed get availableSites(): Site[] {
    const initialValue: any = [];
    if (firebase.auth().currentUser !== null) {
      const sites = Object.entries(this.sites)
        .filter(site => {
          if (site[1].users && site[1].users[firebase.auth().currentUser.uid]) {
            return site;
          } else if (this.currentUser != null && this.currentUser.godmode) {
            return site;
          }
        }).reduce((obj, item) => {
          obj.push(item[1]);
          return obj;
        }, initialValue);
        return sites;
    }
    return [];
  }

  @computed get listOfScreens(): Screen[] {
    // console.log("LIST OF SCREENS", this.screens[localStorage.getItem('siteId')])
    const initialValue: any = [];
    const screens = Object.entries(this.screens)
      .filter(screen => {
        if (screen[1] !== undefined) {
          if (screen[0] === localStorage.getItem('siteId')) {
            return true;
          }
        }
        return false;
      }).map((screenObj) => {
        let values: any = screenObj[1];
        values = Object.values(values).filter((x) => x);
        screenObj[1] = values;
        return screenObj;
      }).reduce((obj, item) => {
        // console.log("ITEM", item)
        if (item !== undefined) {
          return item[1];
        }
      }, initialValue) as Screen[];

      return screens;
  }

  @computed get listOfCollections(): Presentation[] {
    const initialValue: any = [];
    // console.log("List of collections: ")
    return Object.entries(this.collections)
      .filter(collection => {
        if (collection[1].siteId === localStorage.getItem('siteId') ||
          collection[1].siteId === undefined) {
          return Object.values(collection[1]);
        } else if (this.currentUser != null && this.currentUser.godmode) {
          return Object.values(collection[1]);
        }
      })
      .reduce((obj, item) => {
        obj.push(item[1]);
        return obj;
      }, initialValue) as Presentation[];
  }

  @computed get libraryStore(): Media[] {
    const initialValue: any = [];
    // console.log("LIBRARY STORE LOADED:")
    const user = this.currentUser;
    const filteredLibrary = Object.entries(this.library)
    .filter(item => {
      if (item[1] !== undefined) {
          if (item[1].siteId === localStorage.getItem('siteId') ||
            item[1].siteId === undefined) {
            return item;
          } else if (user != null && user.godmode) {
            return item;
          }
        }

      })
      .sort((media_1, media_2) => {
        if (media_1[1].uploadedDate < media_2[1].uploadedDate) {
          return 1;
        } else {
          return -1;
        }
      })
      .reduce((obj, item) => {
        obj.push(item[1]);
        return obj;
      }, initialValue) as Media[];
    // console.log("YEPPER ", Object.entries(this.library).length)
    return filteredLibrary;
  }

  @computed get availableUsers(): User[] {
    const users = Object.values(this.users)
      .filter(item => {
        if (item !== undefined) {
          return item;
        }
      });
    return users;
  }


  /**
   * Sponsors
   */

  @action async updateSponsor(sponsorInput: Sponsor): Promise<any> {
    const sponsorNodeRef = this.dbRootNodeRef.child('sponsors');
    const updateObject = {};
    const initialValue: any = {};

    const filteredSponsors = Object.entries(this.sponsors)
      .filter(sponsor => {
        if (sponsor[1] !== undefined) {
          if (sponsor[1].id === sponsorInput.id) {
            return sponsor;
          }
        }
      })
      .reduce((obj, item) => obj, initialValue);

    if (this.sponsors[filteredSponsors[0]]) {
      this.sponsors[filteredSponsors[1]] = sponsorInput;

      updateObject[filteredSponsors[1]] = sponsorInput;

      return sponsorNodeRef.update(updateObject);
    } else {
      return sponsorNodeRef.push().then(action(pushRef => {
        const sponsorKey: string = pushRef.key;

        // Local update
        this.sponsors[sponsorInput.id] = sponsorInput;

        // Database update
        updateObject[sponsorInput.id] = this.sponsors[sponsorInput.id];
        sponsorNodeRef.update(updateObject);
        return sponsorInput.id;
      }));
    }
  }

  @computed get siteSponsors(): Sponsor[] {
    const initialValue: any = [];
    return Object.entries(this.sponsors)
      .filter(sponsor=> {
        if (sponsor[1].siteId === localStorage.getItem('siteId')) {
          return sponsor;
        }
      })
      .reduce((obj, item) => {
        obj.push(item[1]);
        return obj;
      }, initialValue) as Sponsor[];
  }


  @action async removeSponsor(sponsor: Sponsor): Promise<any> {
    const sponsorNodeRef = this.dbRootNodeRef.child('sponsors');
    const updateObject = {};

    if (this.sponsors[sponsor.id]) {
      delete this.sponsors[sponsor.id];
      updateObject[sponsor.id] = null;
      return sponsorNodeRef.update(updateObject);
    }
  }

  /**
   * Sponsor tiers
   */
  @action async updateSponsorTier(sponsorTierInput: SponsorTier): Promise<any> {
    const sponsorTierNodeRef = this.dbRootNodeRef.child('sponsorTiers');
    const updateObject = {};
    const initialValue: any = {};

    const filteredSponsorTiers = Object.entries(this.sponsorTiers)
      .filter(sponsorTier => {
        if (sponsorTier[1] !== undefined) {
          if (sponsorTier[1].id === sponsorTierInput.id) {
            return sponsorTier;
          }
        }
      })
      .reduce((obj, item) => obj, initialValue);

    if (this.sponsorTiers[filteredSponsorTiers[0]]) {
      this.sponsorTiers[filteredSponsorTiers[1]] = sponsorTierInput;

      updateObject[filteredSponsorTiers[1]] = sponsorTierInput;

      return sponsorTierNodeRef.update(updateObject);
    } else {
      return sponsorTierNodeRef.push().then(action(pushRef => {
        const sponsorKey: string = pushRef.key;

        // Local update
        this.sponsorTiers[sponsorTierInput.id] = sponsorTierInput;

        // Database update
        updateObject[sponsorTierInput.id] = this.sponsorTiers[sponsorTierInput.id];
        sponsorTierNodeRef.update(updateObject);
        return sponsorTierInput.id;
      }));
    }
  }

  @action async removeSponsorTier(sponsorTier: SponsorTier): Promise<any> {
    const sponsorTierNodeRef = this.dbRootNodeRef.child('sponsorTiers');
    const updateObject = {};

    if (this.sponsorTiers[sponsorTier.id]) {
      delete this.sponsorTiers[sponsorTier.id];
      updateObject[sponsorTier.id] = null;
      return sponsorTierNodeRef.update(updateObject);
    }
  }

  @computed get siteSponsorTiers(): SponsorTier[] {
    const initialValue: any = [];
    return Object.entries(this.sponsorTiers)
      .filter(sponsorTiers => {
        if (sponsorTiers[1].siteId === localStorage.getItem('siteId')) {
          return sponsorTiers;
        }
      })
      .reduce((obj, item) => {
        obj.push(item[1]);
        return obj;
      }, initialValue) as SponsorTier[];
  }

  /**
   * Players
   */

   @action async updatePlayer(playerInput: Player): Promise<any> {
    const playerNodeRef = this.dbRootNodeRef.child('players');
    const updateObject = {};
    const initialValue: any = {};

    const filteredPlayers = Object.entries(this.players)
      .filter(player => {
        if (player[1] !== undefined) {
          if (player[1].id === playerInput.id) {
            return player;
          }
        }
      })
      .reduce((obj, item) => obj, initialValue);

    if (this.players[filteredPlayers[0]]) {
      this.players[filteredPlayers[1]] = playerInput;

      updateObject[filteredPlayers[1]] = playerInput;

      return playerNodeRef.update(updateObject);
    } else {
      return playerNodeRef.push().then(action(pushRef => {
        const playerKey: string = pushRef.key;

        // Local update
        this.players[playerInput.id] = playerInput;

        // Database update
        updateObject[playerInput.id] = this.players[playerInput.id];
        playerNodeRef.update(updateObject);
        return playerInput.id;
      }));
    }
  }

  @action async removePlayer(player: Player): Promise<any> {
    const playerNodeRef = this.dbRootNodeRef.child('players');
    const updateObject = {};

    if (this.players[player.id]) {
      delete this.players[player.id];
      updateObject[player.id] = null;
      return playerNodeRef.update(updateObject);
    }
  }

  @computed get sitePlayers(): Player[] {
    const initialValue: any = [];
    return Object.entries(this.players)
      .filter(player => {
        if (player[1].siteId === localStorage.getItem('siteId')) {
          return player;
        }
      })
      .reduce((obj, item) => {
        obj.push(item[1]);
        return obj;
      }, initialValue) as Player[];
  }

  /**
   * Teams
   */
   @action async updateTeam(teamInput: Team): Promise<any> {
    const teamNodeRef = this.dbRootNodeRef.child('teams');
    const updateObject = {};
    const initialValue: any = {};

    const filteredTeams = Object.entries(this.teams)
      .filter(team => {
        if (team[1] !== undefined) {
          if (team[1].id === teamInput.id) {
            return team;
          }
        }
      })
      .reduce((obj, item) => obj, initialValue);

    if (this.teams[filteredTeams[0]]) {
      this.teams[filteredTeams[1]] = teamInput;

      updateObject[filteredTeams[1]] = teamInput;

      return teamNodeRef.update(updateObject);
    } else {
      return teamNodeRef.push().then(action(pushRef => {
        const teamKey: string = pushRef.key;

        // Local update
        this.teams[teamInput.id] = teamInput;

        // Database update
        updateObject[teamInput.id] = this.teams[teamInput.id];
        teamNodeRef.update(updateObject);
        return teamInput.id;
      }));
    }
  }

  @action async removeTeam(team: Team): Promise<any> {
    const eventNodeRef = this.dbRootNodeRef.child('teams');
    const updateObject = {};

    if (this.teams[team.id]) {
      delete this.teams[team.id];
      updateObject[team.id] = null;
      return eventNodeRef.update(updateObject);
    }
  }

  @computed get siteTeams(): Team[] {
    const initialValue: any = [];
    return Object.entries(this.teams)
      .filter(team => {
        if (team[1].siteId === localStorage.getItem('siteId')) {
          return team;
        }
      })
      .reduce((obj, item) => {
        obj.push(item[1]);
        return obj;
      }, initialValue);
  }

  @computed get currentTeam(): Team {
    if (!localStorage.getItem('siteId')) {
      return null;
    }
    const currentTeam = Object.values(this.teams)
      .filter(team => {
        if (this.currentSite) {
          if (team.id === this.currentSite.teamId) {
            return team;
          }
        }
      })
      .reduce((_, team) => team, {} as Team);
    return currentTeam;
  }

  /**
   * Events
   */
  @action async updateEvent(eventInput: Event): Promise<any> {
    const eventNodeRef = this.dbRootNodeRef.child('events');
    const updateObject = {};
    const initialValue: any = {};

    const filteredEvents = Object.entries(this.events)
      .filter(event => {
        if (event[1] !== undefined) {
          if (event[1].id === eventInput.id) {
            return event;
          }
        }
      })
      .reduce((obj, item) => obj, initialValue);

    if (this.events[filteredEvents[0]]) {
      this.events[filteredEvents[1]] = eventInput;

      updateObject[filteredEvents[1]] = eventInput;

      return eventNodeRef.update(updateObject);
    } else {
      return eventNodeRef.push().then(action(pushRef => {
        const eventKey : string = pushRef.key;

        // Local update
        this.events[eventInput.id] = eventInput;

        // Database update
        updateObject[eventInput.id] = this.events[eventInput.id];
        eventNodeRef.update(updateObject);
        return eventInput.id;
      }));
    }
  }

  @action async removeEvent(event: Event): Promise<any> {
    const eventNodeRef = this.dbRootNodeRef.child('events');
    const updateObject = {};

    if (this.events[event.id]) {
      delete this.events[event.id];
      updateObject[event.id] = null;
      return eventNodeRef.update(updateObject);
    }
  }

  @computed get siteEvents(): Event[] {
    const initialValue: any = [];
    return Object.entries(this.events)
      .filter(event => {
        if (event[1].siteId === localStorage.getItem('siteId')) {
          return event;
        }
      })
      .reduce((obj, item) => {
        obj.push(item[1]);
        return obj;
      }, initialValue);
  }

  /**
   * Helper
   */
  // const convertEntriesToObject = (array, key) => {
  //   const initialValue = {};
  //   return array.reduce((obj, item) => {
  //     return {
  //       ...obj,
  //       [item[key]]: item,
  //     };
  //   }, initialValue);
  // };
}
