/* Ionic & Angular core imports */
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';

/* Components */
import { GlobalComponentsModule } from '../global/global.components.module';
import { CollectionEditorComponent } from './collection-editor/collection-editor';
import { AutoPlayConfigPopoverComponent } from './autoplay-config/autoplay-config';
import {  MediaAdderPopoverComponent } from './media-adder/media-adder';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

@NgModule({
	declarations: [
    CollectionEditorComponent,
    AutoPlayConfigPopoverComponent,
    MediaAdderPopoverComponent
  ],
	imports: [
    IonicModule,
    GlobalComponentsModule,
    CommonModule,
    FormsModule
  ],
	exports: [
    CollectionEditorComponent,
    GlobalComponentsModule,
    MediaAdderPopoverComponent
  ],
  entryComponents: [
    CollectionEditorComponent,
    AutoPlayConfigPopoverComponent,
    MediaAdderPopoverComponent
  ]
})
export class CollectionsComponentsModule {}
