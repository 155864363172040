import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { EventsPageRoutingModule } from './events-routing.module';

import { EventsPage } from './events.page';
import { MobxAngularModule } from 'mobx-angular';
import { GlobalComponentsModule } from 'src/app/components/global/global.components.module';
import { EventsComponentsModule } from 'src/app/components/events/events.components.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    MobxAngularModule,
    GlobalComponentsModule,
    EventsComponentsModule,
    EventsPageRoutingModule
  ],
  declarations: [EventsPage]
})
export class EventsPageModule {}
