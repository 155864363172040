/* Ionic & Angular core imports */
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { MobxAngularModule } from 'mobx-angular';
import { GlobalComponentsModule } from '../global/global.components.module';

/* Components */
import { SponsorEditorComponent } from './sponsor-editor/sponsor-editor.component';
import { SponsorPickerComponent } from './sponsor-picker/sponsor-picker.component';
import { SponsorTierEditorComponent } from './sponsor-tier-editor/sponsor-tier-editor.component';
import { SponsorTierPickerComponent } from './sponsor-tier-picker/sponsor-tier-picker.component';

@NgModule({
	declarations: [
    SponsorEditorComponent,
    SponsorPickerComponent,
    SponsorTierEditorComponent,
    SponsorTierPickerComponent
  ],
  imports: [
    IonicModule,
    FormsModule,
    GlobalComponentsModule,
    CommonModule
  ],
  exports: [
    SponsorEditorComponent,
    SponsorPickerComponent,
    SponsorTierEditorComponent,
    SponsorTierPickerComponent
  ],
  entryComponents: [
    SponsorEditorComponent,
    SponsorPickerComponent,
    SponsorTierEditorComponent,
    SponsorTierPickerComponent
  ]
})
export class SponsorsComponentsModule {}
