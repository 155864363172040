/* Ionic & Angular core imports */
import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';

/* Types & interfaces */
import { Media } from '../../../stores/media.store';
import { Screen } from '../../../stores/screen.store';

/* Videogular */
import { VgApiService } from '@videogular/ngx-videogular/core';
import { ApplicationConfig } from 'src/environments/environment';

/* Providers */
import { ApplicationProvider } from '../../../providers/application/application';
import { FirebaseProvider2 } from 'src/app/providers/firebase/firebase';


@Component({
  selector: 'app-media-preview',
  templateUrl: 'media-preview.html',
  styleUrls: ['media-preview.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MediaPreviewComponent implements OnInit, OnDestroy {

  selectedScreen: string;
  media: Media;
  api: VgApiService;
  externalServerAddress: string;

  constructor(
    public changeDetectorRef: ChangeDetectorRef,
    public fbProvider: FirebaseProvider2,
    private viewCtrl: ModalController,
    private appProvider: ApplicationProvider,
    params: NavParams,
    appConfig: ApplicationConfig
  ) {
    this.media = params.get('media');
    this.externalServerAddress = appConfig.externalLibrary.viewURL;
  }

  ngOnInit() { }
  ngOnDestroy() { }

  async displayMedia() {
    if (!this.fbProvider.listOfScreens) {
      this.appProvider.showInformation('List of screens not loaded');
      return;
    }
    this.dismiss();
  }

  cancelMove() {
    this.selectedScreen = 'none';
  }

  /**
   * Function will close modal
   */
  dismiss() {
    this.viewCtrl.dismiss({
      selectedScreen: this.selectedScreen,
      media: this.media
    });
  }

  /**
   * We can access video player api only whe the player is
   * fully initialized. This is the callback called when player
   * is ready.
   *
   * @param api VgAPI - video player api
   */
  onPlayerReady(api: VgApiService) {
    this.api = api;
  }
}
